import { accountUrls, appearanceUrls, campaignUrls } from './Urls';
import { addCDN } from './utils/file';
import { getItem } from './utils/storage';

export const SELECTED_ENVIRONMENT_KEY = 'app_env';

export const STAGING_ENV = 'staging';
export const PROD_ENV = 'prod';
export const ENTERPRISE_ENV = 'enterprise';

export const ENVIRONMENTS = {
  STAGING_ENV,
  PROD_ENV,
  ENTERPRISE_ENV,
};

export const ENVIRONMENT_OPTIONS = [
  { label: 'Staging', value: STAGING_ENV },
  { label: 'Production (Classic)', value: PROD_ENV },
  { label: 'Enterprise', value: ENTERPRISE_ENV },
];

export const STAGING_SELLER_LINK = 'staging.web.mydukaan.io';
export const STAGING_SELLER_LINK_V2 =
  'seller-web-git-staging-dukaan-tech.vercel.app';

export const ENV_SWITCH_ENABLED_HOSTNAMES = [
  STAGING_SELLER_LINK,
  STAGING_SELLER_LINK_V2,
  'localhost',
  'netlify.app',
];

export const isNonLiveEnv = ENV_SWITCH_ENABLED_HOSTNAMES.some((address) =>
  (window.location.hostname ?? '').includes(address)
);

export let activeEnv = null;

const { host } = window.location;

const isClassic =
  host.startsWith('admin.') ||
  host.startsWith('web.') ||
  host.startsWith('www.web');

const isStaging = host.startsWith('staging.web');

const selectedEnv =
  getItem(SELECTED_ENVIRONMENT_KEY) ?? JSON.stringify(ENVIRONMENT_OPTIONS[1]); // set production as default

if (isClassic) {
  activeEnv = ENVIRONMENTS.PROD_ENV;
} else if (isStaging) {
  activeEnv = ENVIRONMENTS.STAGING_ENV;
} else if (host.includes('web.mydukaan.io') || host.includes('marketplace.')) {
  // enterprise case
  activeEnv = ENVIRONMENTS.ENTERPRISE_ENV;
} else {
  const { value } = JSON.parse(selectedEnv);
  activeEnv = value;
}

const baseUrls = {
  [STAGING_ENV]: {
    BASE_URL: 'https://staging.api.dukaaninfra.in',
    BASE_V2_URL: 'https://staging.seller-front.api.dukaaninfra.in',
    RAZORPAY_API_KEY: 'rzp_test_XAhBIfmf4ZjZwC',
    SOCKET_URL: 'https://socket-staging.dukaaninfra.in',
  },
  [PROD_ENV]: {
    BASE_URL: 'https://api.mydukaan.io',
    BASE_V2_URL: 'https://api.mydukaan.io',
    RAZORPAY_API_KEY: 'rzp_live_trvtrdDmS56QkN',
    SOCKET_URL: 'https://stream.mydukaan.io',
  },
  [ENTERPRISE_ENV]: {
    BASE_URL: 'https://api-enterprise.mydukaan.io',
    BASE_V2_URL: 'https://api-enterprise.mydukaan.io',
    RAZORPAY_API_KEY: 'rzp_live_trvtrdDmS56QkN',
    SOCKET_URL: 'https://stream.mydukaan.io',
  },
};

const { BASE_URL } = baseUrls[activeEnv];
const { BASE_V2_URL } = baseUrls[activeEnv];

export const { RAZORPAY_API_KEY } = baseUrls[activeEnv];

export const { SOCKET_URL } = baseUrls[activeEnv];

export const isStagingEnv = activeEnv === STAGING_ENV;

export const ASGARD_DOMAIN = 'https://asgard-enterprise.mydukaan.io/api/asgard';

export const GOOGLE_CLIENT_ID =
  '193655940279-fqki2oj8geanju1i2g1hoo1lds6svg3c.apps.googleusercontent.com';

export const GOOGLE_API_KEY = 'AIzaSyCc5pw8so3aXFhTrW3IGS5eplejXu9K1ak';

export const RECAPTCHA_SITE_KEY = '6Le1AaQjAAAAAG7VprkQcNmuuQsqDM93Yu_v7yNk';
export const AMPLITUDE_KEY = 'ecc9642a5bfe88a8a40700a5156ea856';
export const MIXPANEL_TOKEN = '2ea69e0da5708f0ac343a096d90809a7';

export const LINK_MINK_KEY = 'pub_live_zdfQ2hFIIo1pIQIK9KuQ';
export const TINY_MCE_KEY = 'kcl9ooqnzvjsswraa2ydqjnr10eh633zc3236f16fzm7cdbr';

export const GOOGLE_PEOPLE_API_DISCOVERY_DOC =
  'https://www.googleapis.com/discovery/v1/apis/people/v1/rest';

export const GOOGLE_CONTACT_SCOPE_URL =
  'https://www.googleapis.com/auth/contacts.readonly';

export const GOOGLE_IDENTITY_SERVICES_URL =
  'https://accounts.google.com/gsi/client';

export const GOOGLE_APIS_URL = 'https://apis.google.com/js/api.js';

export const STORE_DOMAIN = BASE_URL;
export const STORE_V2_DOMAIN = BASE_V2_URL;
export const AUTH_DOMAIN = BASE_URL;
export const ORDER_DOMAIN = BASE_URL;
export const ORDER_V2_DOMAIN = BASE_V2_URL; // for staging use baseurl

export const APP_DOMAIN = 'https://mydukaan.io/';
export const SELLER_WEB_DOMAIN = 'https://web.mydukaan.io/';
export const DUKAAN_LINKTREE_URL = 'https://linktr.ee/dukaan';
export const APP_DOMAIN_BASE_URL = 'mydukaan.io/';
export const APP_DOMAIN_ENT_BASE_URL = 'ent.mydukaan.io/';
export const APP_DOMAIN_STAGING_BASE_URL = 'staging.v2.dukaaninfra.in/';
export const STORE_LOCATOR_URL = 'https://store-locator.apps.mydukaan.io';

export const DUKAAN_MEDIA_DOMAIN = 'https://dms.mydukaan.io';

export const RATE_US_URL =
  'https://play.google.com/store/apps/details?id=com.dukaan.app';

export const CUSTOMER_SUPPORT_NUMBER = '+91-8047486372';

export const DUKAAN_BOT9_BOT_URL = 'https://dukaan.bot9.ai/';

export const KYC_SUPPORT_NUMBER = '+91-9513386883';

export const CUSTOMER_SUPPORT_EMAIL = 'support@mydukaan.io';

export const DUKAAN_TERMS_AND_CONDITION = 'https://mydukaan.io/terms';

export const DUKAAN_COACH_URL = 'https://dukaan.coachautomatic.com';

export const INTERCOM_KNOWLEDGEBASE_URL = 'https://help.mydukaan.io/';
export const HELP_CENTER_URL = 'https://help.mydukaan.io';
export const DUKAAN_HELP = 'https://dukaanhelp.com/';
export const DUKAAN_CHANGELOG =
  'https://dukaan.help.center/article/1020-changelog';
export const DUKAAN_HELP_STAGING =
  'https://staging--joyful-druid-d88eb0.netlify.app/';

export const DUKAAN_COMMUNITY_URL = 'https://www.facebook.com/groups/dukaanapp';
export const DUKAAN_COMMUNITY_INTL_URL =
  'https://www.facebook.com/groups/316594216644571/';

export const PRIVACY_URL = 'https://mydukaan.io/privacy';

export const HOW_TO_VIDEO_URL =
  'https://www.youtube.com/playlist?list=PLs_gKdv4olrZECsyNrKUjuALeqiec4snN';

export const APPS_DOMAIN = 'https://apps.mydukaan.io';

export const NEWSLETTER_APP_DOMAIN = 'https://mailchimp.apps.mydukaan.io';

export const DUNZO_APP_DOMAIN = 'https://dunzo.apps.mydukaan.io';

export const CURRENCY_SYMBOL = '₹';
export const CURRENCY_CODE = 'INR';
export const PERCENTAGE_SYMBOL = '%';

export const MOBILE_MAX_WIDTH = 768;
export const TABLET_MAX_WIDTH = 1024;
export const EXTRA_LARGE_SCREEN_WIDTH = 1920;

export const PLACEHOLDER_IMAGE =
  'https://api.mydukaan.io/static/images/category-def.jpg';

export const STORE_PLACEHOLDER_IMAGE = addCDN(
  'https://api.mydukaan.io/static/images/store-def.jpg',
  200,
  200
);

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCTcC_7IDDeyP38GE3-MXIqp7S589tH9Rs';
export const FACEBOOK_APP_ID = '324740302245745';

export const VERSION_CODE = process.env.BUILD_VERSION || '1.0.8';

export const PRODUCT_DESCRIPTION_GENERATOR_URL =
  'https://mydukaan.io/tools/product-description-generator';

export const SHIPPO_API_DASHBOARD = 'https://apps.goshippo.com/settings/api';

export const APPS_SUBSCRIPTION_DEEP_LINK =
  'https://mydukaan.page.link/notification?source=dukaan_premium';

export const CREDITS_READMORE_LINK =
  'https://help.mydukaan.io/article/1015-dukaan-credits-bills-invoicing';

export const DUKAAN_SERVICE_FEE_URL =
  'https://help.mydukaan.io/article/1016-store-plan-terms-fee-update-oct-2023';

export const PAYMENT_HELP_URL =
  'https://help.mydukaan.io/category/108-payments';

export const HELP_LINKS = {
  ORDERS:
    'https://help.mydukaan.io/en/articles/5000546-how-to-use-the-orders-section',
  ABANDONED_ORDERS:
    'https://help.mydukaan.io/en/articles/6375945-how-to-use-the-abandoned-cart-section',
  EDIT_ORDER:
    'https://help.mydukaan.io/en/articles/5054913-how-to-edit-an-order',
  ADD_PRODUCT:
    'https://help.mydukaan.io/en/articles/5000540-how-to-add-a-single-product',
  ADD_PRODUCT_INTL:
    'https://help.mydukaan.io/en/articles/5838696-how-to-add-a-single-product',
  ADD_BULK_BASIC:
    'http://help.mydukaan.io/en/articles/5000529-how-to-bulk-add-products',
  ADD_BULK_ADVANCE:
    'https://help.mydukaan.io/en/articles/6361419-bulk-upload-for-advance-catalogue',
  DINING:
    'https://help.mydukaan.io/en/articles/5000522-how-to-use-restaurant-dining',
  PAYMENTS_INDIA:
    'https://help.mydukaan.io/en/articles/5006717-how-to-use-online-payments-for-your-store',
  PAYMENTS_INTERNATIONAL:
    'https://help.mydukaan.io/en/articles/5762143-how-to-set-online-payments-for-your-store',
  SETUP_STRIPE:
    'https://help.mydukaan.io/en/articles/5207597-how-to-integrate-stripe-with-dukaan',
  SETUP_PAYPAL:
    'https://help.mydukaan.io/article/5145-how-to-integrate-paypal-with-dukaan',
  EXTRA_CHARGES:
    'https://help.mydukaan.io/en/articles/5000518-how-to-use-extra-charges',
  EXTRA_CHARGES_RESTAURANT:
    'https://help.mydukaan.io/en/articles/5006716-how-to-use-extra-charges-for-hotel-restaurants',
  STORE_QR:
    'https://help.mydukaan.io/en/articles/5000515-how-to-use-store-qr-code',
  COUPONS:
    'https://help.mydukaan.io/en/articles/5000508-how-to-use-discount-coupons',
  WHATSAPP_STORIES:
    'https://help.mydukaan.io/en/articles/5000503-how-to-use-whatsapp-stories',
  STORE_BANNERS:
    'https://help.mydukaan.io/en/articles/5000499-how-to-use-store-banners',
  BUSINESS_CARDS:
    'https://help.mydukaan.io/en/articles/5000496-how-to-use-business-card',
  MY_CUSTOMERS:
    'https://help.mydukaan.io/en/articles/5151332-how-to-use-the-my-customers-feature',
  CATALOG_BUILDER:
    'https://help.mydukaan.io/en/articles/5113559-how-to-use-catalog-builder',
  INVOICE_GENERATOR:
    'https://help.mydukaan.io/en/articles/5183141-how-to-use-invoice-generator',
  APPEARANCE:
    'https://help.mydukaan.io/en/articles/5230244-how-to-use-appearance',
  PAGES:
    'https://help.mydukaan.io/en/articles/5230684-how-to-add-new-pages-to-dukaan-store',
  STORE_TIMINGS:
    'https://help.mydukaan.io/en/articles/5275825-how-to-use-store-timings',
  CUSTOMIZE_THEME:
    'https://help.mydukaan.io/en/articles/5299812-how-to-customize-my-store',
  GET_OWN_APP:
    'https://help.mydukaan.io/en/articles/5298668-how-to-get-my-own-app-for-the-store',
  PAYMENT_ON_HOLD:
    'https://help.mydukaan.io/en/articles/5319545-payment-on-hold-why-my-payments-are-on-hold',
  BULK_PRICE_EDIT:
    'https://help.mydukaan.io/en/articles/5373079-how-to-bulk-edit-the-prices-of-all-products',
  ZONAL_DELIVERY_CHARGES:
    'https://help.mydukaan.io/en/articles/5391081-how-to-set-up-pin-code-or-distance-based-delivery-charges',
  REVIEWS:
    'https://help.mydukaan.io/en/articles/5428649-how-to-use-product-reviews-and-ratings-plugin',
  CREDITS:
    'https://help.mydukaan.io/en/articles/5496150-how-to-use-dukaan-credits',
  CAMPAIGN:
    'https://help.mydukaan.io/en/articles/5499424-how-to-use-marketing-campaigns',
  CUSTOMERS_FEATURE:
    'https://help.mydukaan.io/en/articles/5012736-how-to-use-the-my-customers-feature',
  DUKAAN_SHIPPING:
    'https://help.mydukaan.io/en/articles/5516681-how-to-use-dukaan-shipping',
  DUKAAN_DELIVERY:
    'https://help.mydukaan.io/en/articles/5516681-how-to-use-dukaan-delivery',
  CATEGORIES:
    'https://help.mydukaan.io/en/articles/5579900-how-to-create-a-category',
  SHIPPO:
    'https://help.mydukaan.io/en/articles/5781751-how-to-use-the-shippo-plugin',
  CUSTOM_DOMAIN:
    'https://help.mydukaan.io/en/articles/4699700-how-can-i-add-my-custom-domain-name-from-dukaan',
  EXISTING_CUSTOM_DOMAIN:
    'https://help.mydukaan.io/en/articles/5415559-how-to-link-your-third-party-custom-domain-on-dukaan',
  STAFF_ACCOUNT:
    'https://help.mydukaan.io/en/articles/5963112-how-to-use-the-staff-account-feature',
  ALL_IN_ONE_SEO:
    'https://help.mydukaan.io/en/articles/5688610-how-to-use-all-in-one-seo-plugin',
  AUTO_ACCEPT_ORDERS:
    'https://help.mydukaan.io/en/articles/6196974-how-to-auto-accept-orders',
  EXTRA_CHARGES_STORE_SETTINGS:
    'https://help.mydukaan.io/en/articles/5000518-how-to-use-extra-charges-on-web',
  INTL_TAX:
    'https://help.mydukaan.io/en/articles/6281825-how-to-set-up-taxes-for-your-store',
  PREFERENCES_STORE_SETTINGS:
    'https://help.mydukaan.io/en/articles/6302794-how-to-use-preferences-section-on-web',
  SENDER_EMAIL:
    'https://help.mydukaan.io/en/articles/6302159-how-to-use-smtp-mail-service-on-dukaan',
  DISABLING_COMMUNICATIONS:
    'https://help.mydukaan.io/en/articles/6360884-disabling-communications',
  FORWARDING_EMAIL:
    'https://help.mydukaan.io/en/articles/6325494-how-to-set-up-forwarding-emails-for-your-store',
  NUDGIFY:
    'https://help.mydukaan.io/en/articles/6230079-how-to-use-the-nudgify-plugin',
  MAIL_MUNCH:
    'https://help.mydukaan.io/en/articles/6230126-how-to-use-the-mailmunch-plugin',
  BAG_CONTROL:
    'https://help.mydukaan.io/en/articles/4696916-how-to-setup-minimum-order-value-or-maximum-order-value',
  COOKIE_YES:
    'https://help.mydukaan.io/en/articles/6386648-how-to-use-the-cookieyes-plugin',
  USER_LIKE:
    'https://help.mydukaan.io/en/articles/6388960-how-to-use-the-userlike-plugin',
  RTO_OPTIMISER_PLUGIN:
    'https://help.mydukaan.io/en/articles/6365138-how-to-use-the-rto-optimizer-plugin',
  WISHLIST_PLUGIN:
    'https://help.mydukaan.io/en/articles/6498050-how-to-use-the-wishlist-plugin',
  WAREHOUSE:
    'https://help.mydukaan.io/en/articles/6335599-how-to-add-multiple-warehouses',
  BLOG: 'https://help.mydukaan.io/en/articles/6486346-how-to-add-a-blog-to-my-store',
  PRODUCT_ATTRIBUTES:
    'https://help.mydukaan.io/en/articles/6511316-how-to-use-the-product-attributes-plugin',
  GOOGLE_MERCHANT_CENTER_PLUGIN:
    'https://help.mydukaan.io/en/articles/6533654-how-to-use-the-google-merchant-center-plugin',
  COUNTDOWN_TIMER_PLUGIN:
    'https://help.mydukaan.io/en/articles/6538423-how-to-use-the-countdown-timer-plugin',
  HELLOBAR_PLUGIN:
    'https://help.mydukaan.io/en/articles/6538074-how-to-use-the-hellobar-plugin',
  WEBHOOK_PLUGIN:
    'https://help.mydukaan.io/en/articles/6631793-how-to-use-the-webhooks-plugin',
  RETURNS_AND_REPLACEMENT:
    'https://help.mydukaan.io/en/articles/6643096-how-do-returns-and-replacements-work',
  LANGUAGES:
    'https://help.mydukaan.io/en/articles/6636464-how-to-add-additional-languages-to-my-store',
  WOOCOMMERCE:
    'https://help.mydukaan.io/en/articles/6560554-how-to-use-the-woocommerce-importer',
  LOYALTY_POINTS:
    'https://help.mydukaan.io/en/articles/6563544-how-to-use-the-loyalty-points-plugin',
  GST: 'https://help.mydukaan.io/en/articles/6758629-how-to-set-up-taxes-for-your-indian-stores',
  GUMROAD_PRODUCT_IMPORTER:
    'https://help.mydukaan.io/en/articles/6838957-how-to-use-the-gumroad-importer-plugin',
  SHIPPING_GUIDLINES:
    'https://help.mydukaan.io/en/articles/6855410-dukaan-delivery-guidelines',
  ORDER_FORM:
    'https://help.mydukaan.io/en/articles/5041874-how-to-use-the-order-form-on-web',
  CUSTOM_CSS:
    'https://help.mydukaan.io/en/articles/6485513-how-to-add-custom-css-to-my-store',
  SHIPPING:
    'https://help.mydukaan.io/en/articles/6224194-how-to-use-the-shipping-section-in-store-settings',
  ABANDONDED_CART:
    'https://help.mydukaan.io/en/articles/6385929-how-to-use-the-automated-abandoned-cart-recovery-feature',
  UPGRADE_PLAN:
    'https://help.mydukaan.io/en/articles/6092289-how-to-upgrade-my-store-plan',
  ANALYTICS_TRAFFIC:
    'https://help.mydukaan.io/en/articles/6549433-how-to-use-the-dukaan-analytics-dashboard-traffic',
  ANALYTICS_SALES:
    'https://help.mydukaan.io/en/articles/6549418-how-to-use-the-dukaan-analytics-dashboard-sales',
  ANALYTICS_PRODUCTS:
    'https://help.mydukaan.io/en/articles/6549436-how-to-use-the-dukaan-analytics-dashboard-products',
  SIZE_CHART_PLUGIN:
    'https://help.mydukaan.io/en/articles/6901521-how-to-add-size-charts-to-my-products',
  STORE_LOCATOR_PLUGIN:
    'https://help.mydukaan.io/en/articles/6524797-how-to-use-the-store-locator-plugin',
  WEBHOOKS_PLUGIN:
    'https://help.mydukaan.io/en/articles/6631793-how-to-use-the-webhooks-plugin',
  DUKAAN_PAY_SETTLEMENTS:
    'https://help.mydukaan.io/en/articles/5111238-how-do-online-payments-settlements-work',
};

export const GRID_VIEW = 'grid';
export const LIST_VIEW = 'list';

export const HTTP_FORBIDDEN_STATUS_CODE = 403;

export const GAPI_SCOPE_PERMISSION_DENIED = 'PERMISSION_DENIED';

export const RETURNS_FEATURE = 'returns';
export const LANGUAGES_FEATURE = 'languages';
export const CONNECT_SHIPPING_ACCOUNTS_FEATURE = 'connect_shipping_accounts';

export const AMAZON_REVIEWS_FROM = {
  VERIFIED: 1,
  ALL: 0,
  NON_VERIFIED: -1,
};

export const VU_STORE_ID = 101509992;
export const WOWTRENDS_PROD_ID = 101089667;
export const GINY_AND_JONY_PROD_ID = 102003183;
export const GINY_AND_JONY_STAGING_ID = 2602;
export const TEST_STORE_3_STAGING_ID = 247;
export const TEST_STORE_PROD_ID = 60025090;
export const TEST_STORE_2_PROD_ID = 101089667;
export const TEST_STORE_3_PROD_ID = 101415328;
export const LIPIKA_TEST_STORE = 101838467;
export const SURYANSH_TEST_STORE = 101748963;
export const SUMIT_TEST_STORE = 101596668;
export const OFFDUTY_STORE = 102074984;
export const OFFDUTY_PROD_STORE = 102170163;
export const CAMISA_STORE = 101552988;
export const BIGHAAT_STORE = 102125067;
export const HEALTH_XP_STORE = 102299062;
export const FYL360_HEALTHCARE_STORE = 102158932;
export const SINGAPORE_BAZAAR_STORE = 101982322;
export const SHOP_NISHANT_STORE = 101462290;
export const UPPERCASE_STORE = 102202974;
export const YASHWANTH_STAGING_STORE = 4165;
export const APPLE_CIDER_VINEGAR_STORE = 102277435;
export const HEALTH_XP_TESTING_STORE = 102276807;
export const WOWSTORE = 102299050; // https://wowskinscienceindia.com/
export const LEMONADE_SHOP_STORE = 102299087;
export const HEALTHXP_STAGING_STORE = 4171;
export const BIGHAAT_STAGING_STORE = 4;
export const DUKAAN_TEST_STORE = 11;
export const MULMUL_STORE = 202299198;
export const DUKAAN_STAGE_TEST_STORE = 15;
export const BUYWOW_ENTERPRISE_STORE = 102299098;
export const BUYWOW_TEST_STORE = 202299278;
export const SYSKA_ENTERPRISE_STORE = 202299218;
export const TREKKIT_ENTERPRISE_STORE = 102299112;
export const TRUKE_STORE = 102273998;
export const BIO_AYURVEDA_STORE = 102192029;
export const THE_WHOLE_TRUTH_STORE = 202299269;
export const CHANDRAKANTH_TEST_STORE = 102433235;
export const THE_12 = 202299439;

export const TWT_SHIPMENT_LINK = 'https://thewholetruthfoods.clickpost.ai/en/';

export const PREFERRED_WAREHOUSE_STORES = [];

export const SHOW_TOGGLE_PAYMENT_PROVIDER_DISCOUNT = [
  BUYWOW_ENTERPRISE_STORE,
  WOWSTORE,
];

export const INITIATE_REFUND_ADVANCED_ENABLED = [
  OFFDUTY_PROD_STORE,
  SINGAPORE_BAZAAR_STORE,
  SHOP_NISHANT_STORE,
  BIGHAAT_STORE,
  HEALTH_XP_STORE,
  LEMONADE_SHOP_STORE,
  SYSKA_ENTERPRISE_STORE,
];

export const VINCULUM_ENABLED_STORES = [DUKAAN_TEST_STORE, MULMUL_STORE];
export const UNICOMMERCE_RETURN_ENABLED_STORES = [
  UPPERCASE_STORE,
  LEMONADE_SHOP_STORE,
  SYSKA_ENTERPRISE_STORE,
  TREKKIT_ENTERPRISE_STORE,
];
export const COMMISSION_WHITELIST_STORES = [
  HEALTH_XP_STORE,
  DUKAAN_STAGE_TEST_STORE,
  102258355,
  102373400, // truedeliver.com
];

export const APP_FORCE_PUSH_ENABLED_STORES = [
  BUYWOW_ENTERPRISE_STORE,
  BUYWOW_TEST_STORE,
];

export const BUNDLES_ENABLED_STORES = [
  BIO_AYURVEDA_STORE,
  THE_WHOLE_TRUTH_STORE,
  CHANDRAKANTH_TEST_STORE,
  TEST_STORE_2_PROD_ID,
  BUYWOW_ENTERPRISE_STORE,
  BUYWOW_TEST_STORE,
  WOWSTORE,
  THE_12,
];

export const UNICOMMERCE_VENDOR_ENABLED_STORES = [THE_12, HEALTH_XP_STORE];

export const MEMBERSHIP_ENABLED_STORES = [THE_12];

export const TRIAL_PERIOD_DEFAULT_DURATION = 7; // 7 days

export const WHITE_LABEL_WHATSAPP_API_UUID =
  '7368caae-08ba-481d-8c80-346ebdf312f6';

export const ADVANCED_HELLOBAR_DOMAIN =
  'https://advance-hellobar.apps.mydukaan.io';
export const COUNTDOWN_TIMER_DOMAIN =
  'https://advance-countdown-timer.apps.mydukaan.io';
export const PLUGIN_META_DOMAIN = 'https://plugin-meta.apps.mydukaan.io';

export const HIDE_SIDEBAR = [
  accountUrls.subscriptionCancelPath,
  appearanceUrls.codeEditor,
  campaignUrls.emailMarketingEditor,
];

export const EMAIL_EDITOR_PROJECT_ID = 119284;

export const AUTO_SHIP_ENABLED_STORES = [
  HEALTH_XP_STORE,
  HEALTH_XP_TESTING_STORE,
];

export const PRODUCT_GST_MANDATORY_STORES = [];

export const SVG_EXTENSION = '.svg';

export const SORTING_ORDER_ASCENDING = 'asc';
export const SORTING_ORDER_DESCENDING = 'desc';

export const ENTERPRISE_CALL_SCHEDULE_CALENDLY =
  'https://mydukaan.page.link/enterprise';

export const DUKAAN_API_DOCS_URL =
  'https://documenter.getpostman.com/view/25389466/2s9Yynk3f7';
export const ENTERPRISE_LEARN_MORE = 'https://mydukaan.io/enterprises';

export const ENTERPRISE_INTERNATIONAL_CALL_SCHEDULE_CALENDLY =
  'https://mydukaan.page.link/enterprise-us';

export const ACCEPTED_IMAGE_TYPES =
  'image/png, image/jpg, image/jpeg, image/webp, image/gif, image/ico, image/svg+xml';
export const ACCEPTED_IMAGE_AND_VIDEO_TYPES = `${ACCEPTED_IMAGE_TYPES}, video/mp4`;

export const ACCEPTED_IMAGE_SIZE = 50000000;
export const ACCEPTED_IMAGE_SIZE_MB = '50MB';

export const ACCEPTED_FILE_SIZE = 50000000;
export const ACCEPTED_FILE_SIZE_MB = '50MB';
export const CONNECT_EXISTING_DOMAIN_VIDEO_URL =
  'https://youtu.be/jLLNd8AlHeU?t=32';
export const SMTP_EMAIL_SERVICE_VIDEO_URL = 'https://youtu.be/9C_91O4YNQc';
