import React from 'react';
import {
  FormCustomSelect,
  FormInput,
  FormNumberInput,
  Validation,
} from '../../../shared/Form';
import { useCountry } from '../../../context/CountryProvider';
import BundleDiscountRadioGroup from './BundleDiscountRadioGroup';
import {
  applicableBundleItems,
  APPLY_BUNDLE_ITEMS_DEFAULT,
  BUNDLE_DISCOUNT_TYPE,
  BUNDLE_TYPE_ID,
} from '../constants';
import { noop } from '../../../utils';
import useModal from '../../../hooks/useModal';
import ProductReorderingModal from '../../../Themes/components/ProductReorderingModal';
import BundleProductQuantityModal from './BundleProductQuantityModal';
import CheckboxField from '../../../shared/CheckboxField';
import { useAppContext } from '../../../context/AppContext';
import { THE_WHOLE_TRUTH_STORE } from '../../../constants';

const CategoryBundleTypeTopSection = ({
  type,
  form,
  values,
  openCategoryModal,
  openProductsModal,
  bundleDetails,
  setSelectedCategoriesWhole = noop,
  setSelectedCategories = noop,
  isBundleDetailsLoading = false,
}) => {
  const { formatLocalMoney } = useCountry();
  const { business } = useAppContext();
  const isTWT = business.id === THE_WHOLE_TRUTH_STORE;

  const {
    isOpen: isOpenReorderModal,
    openModal: openReorderModal,
    closeModal: closeReorderModal,
  } = useModal();

  const {
    isOpen: isOpenQuantityModal,
    openModal: openQuantityModal,
    closeModal: closeQuantityModal,
  } = useModal();

  const discountHelperText = () => {
    if (values?.quantity > 0 && values?.product_bundle_discount_value > 0) {
      return (
        <span className="section-text-7 c-black-3">
          {`Customer can buy ${
            values?.quantity ?? values?.min_quantity
          } products for ${formatLocalMoney(
            values?.product_bundle_discount_value
          )}.`}
        </span>
      );
    }

    if (
      values?.min_quantity > 0 &&
      values?.product_bundle_each_line_item_price > 0
    ) {
      return (
        <span className="section-text-7 c-black-3">
          {`Customer can buy ${
            values?.min_quantity === values?.max_quantity ||
            (values?.min_quantity && !values?.max_quantity)
              ? values?.min_quantity
              : `${values?.min_quantity} to ${values?.max_quantity}`
          } products for ${formatLocalMoney(
            values?.product_bundle_each_line_item_price
          )} each.`}
        </span>
      );
    }
  };

  const handleApplicabilityChange = (props) => {
    if (props?.value !== values?.bundle_applicability) {
      setSelectedCategoriesWhole([]);
      setSelectedCategories([]);
      form.mutators.modifyFormField('product_bundle_applicability', []);
      form.mutators.modifyFormField('categories', []);
      form.mutators.modifyFormField('categories_data', []);
      form.mutators.modifyFormField('category_names', '');
      form.mutators.modifyFormField('products', []);
      form.mutators.modifyFormField('product_names', '');
    }
  };

  const handleUpdateQuantities = (formValues) => {
    const formattedData = {};
    Object.keys(formValues).forEach((key) => {
      const id = key.split('-')[0];
      formattedData[id] = Number(formValues[key]);
    });
    const newApplicabilityArray = values.product_bundle_applicability.map(
      (it) => ({
        ...it,
        quantity: formattedData[it.id],
      })
    );
    form.mutators.modifyFormField(
      'product_bundle_applicability',
      newApplicabilityArray
    );
    closeQuantityModal();
  };

  return (
    <div className="card p24" id="bundle-info">
      <p className="section-text-4 c-black-1">Bundle Information</p>
      <div className="divider mt8 mb24" />
      <FormInput
        name="name"
        placeholder="Enter bundle name"
        labelText="Bundle Name"
        required
        showRequired
        validate={Validation.required()}
        className="mb24"
      />
      {type === BUNDLE_TYPE_ID.x_items_for_y_each ? (
        <p className="section-text-6 c-black-1 mb16">Customer Can Buy</p>
      ) : (
        <> </>
      )}
      <div className="row bundle-information-panel-row">
        <div className="bundle-information-panel-col">
          {type === BUNDLE_TYPE_ID.mix_and_match ? (
            <FormNumberInput
              name="quantity"
              placeholder="Eg. 3"
              appendText="qty"
              labelText="Customer Can Buy"
              className="mb0"
            />
          ) : (
            <FormNumberInput
              name="min_quantity"
              placeholder="Eg. 3"
              appendText="qty"
              labelText="Minimum"
              required
              showRequired
              validate={Validation.required()}
              className="mb0"
            />
          )}
        </div>
        {type === BUNDLE_TYPE_ID.x_items_for_y_each && (
          <div className="bundle-information-panel-col">
            <FormNumberInput
              name="max_quantity"
              placeholder="Eg. 5"
              appendText="qty"
              labelText="Maximum"
              required
              showRequired
              validate={Validation.required()}
              className="mb0"
            />
          </div>
        )}
        {/* WIP: change bundle applicability is hidden intentionally so user is only allowed to select products for bundle
        
        <div className="bundle-information-panel-col">
          <FormCustomSelect
            name="bundle_applicability"
            labelText="From"
            options={applicableBundleItems}
            required
            showRequired
            validate={Validation.required()}
            placeholder="Select options"
            className="mb0"
            selected={APPLY_BUNDLE_ITEMS_DEFAULT.SPECIFIC_PRODUCTS}
            onChange={handleApplicabilityChange}
          />
        </div> */}
      </div>
      <FormInput
        className="category-select-wrap mt24"
        labelText="Products"
        name="product_names"
        required
        validate={Validation.required()}
        readOnly
        showRequired
        onClick={openProductsModal}
        placeholder="Select products"
        badgeOnRight={
          !isBundleDetailsLoading &&
          bundleDetails.id && ( // add update quantity modal here as well
            <div className="d-flex align-center pull-right">
              {/* <div
                className="anchor-1 text-medium mr20"
                onClick={openQuantityModal}
              >
                Update Quantity
              </div> */}
              <div className=" anchor-1 text-medium" onClick={openReorderModal}>
                Reorder products
              </div>
            </div>
          )
        }
      />
      <div className="row">
        <div className="col-md-6">
          <FormInput
            className="category-select-wrap mr20"
            labelText="Bundle Category"
            placeholder="Select category"
            name="category_names"
            onClick={openCategoryModal}
          />
        </div>
        <div className="col-md-6">
          <FormNumberInput
            labelText="Max bundle quantity"
            placeholder="Max no. of times bundle can be brought per order"
            name="max_bundle_qty_in_cart"
          />
        </div>
      </div>
      <div className="mb24">
        <CheckboxField
          checked={values?.product_bundle_only_applicable_for_online_order}
          onChange={(e) =>
            form.mutators.modifyFormField(
              'product_bundle_only_applicable_for_online_order',
              e.target.checked
            )
          }
          name="product_bundle_only_applicable_for_online_order"
          label={
            <span className="ml4">
              Bundle applicable on online payment only
            </span>
          }
        />
      </div>
      {isTWT && (
        <div className="mb24">
          <CheckboxField
            checked={values?.sdd || false}
            onChange={(e) =>
              form.mutators.modifyFormField('sdd', e.target.checked)
            }
            name="sdd"
            label={
              <span className="ml4">
                Same day delivery applicable on this bundle
              </span>
            }
          />
        </div>
      )}
      {type === BUNDLE_TYPE_ID.mix_and_match && (
        <>
          <div className="my24">
            <BundleDiscountRadioGroup form={form} values={values} />
          </div>
          {values.product_bundle_discount_type ===
            BUNDLE_DISCOUNT_TYPE.FLAT_DISCOUNT && (
            <FormNumberInput
              name="product_bundle_discount_value"
              placeholder="Enter bundle price"
              labelText="Bundle Price"
              required
              showRequired
              className="mb0"
              initialValue={bundleDetails?.product_bundle_discount_value}
              validate={Validation.required()}
              helpText={discountHelperText()}
            />
          )}
          {values.product_bundle_discount_type ===
            BUNDLE_DISCOUNT_TYPE.PERCENT_DISCOUNT && (
            <FormNumberInput
              name="product_bundle_discount_value"
              placeholder="Enter bundle discount"
              labelText="Bundle Price"
              required
              showRequired
              className="mb0"
              validate={Validation.required()}
              initialValue={bundleDetails?.product_bundle_discount_value}
              isAllowed={({ floatValue }) =>
                floatValue === undefined || floatValue <= 100
              }
              helpText={
                values?.quantity > 0 &&
                values?.product_bundle_discount_value && (
                  <span className="section-text-7 c-black-3">
                    {`Customer can buy ${
                      values?.quantity
                    } products for ${Math.round(
                      values?.product_bundle_discount_value
                    )}% off.`}
                  </span>
                )
              }
              appendText="%"
            />
          )}
        </>
      )}

      {type === BUNDLE_TYPE_ID.x_items_for_y_each && (
        <FormNumberInput
          name="product_bundle_each_line_item_price"
          placeholder="Enter product price"
          labelText="Individual Product Price"
          required
          showRequired
          className="mt24 mb0"
          validate={Validation.required()}
          helpText={discountHelperText()}
        />
      )}

      {isOpenReorderModal && (
        <ProductReorderingModal
          open={isOpenReorderModal}
          handleClose={closeReorderModal}
          closeReorderModal={closeReorderModal}
          isStaticReordering
          initialProducts={values?.product_bundle_applicability ?? []}
          onChange={(updatedProducts) => {
            const data = updatedProducts.map((it, index) => ({
              ...it,
              position: index,
            }));
            form.mutators.modifyFormField('product_bundle_applicability', data);
          }}
        />
      )}

      {isOpenQuantityModal && (
        <BundleProductQuantityModal
          open={isOpenQuantityModal}
          handleClose={closeQuantityModal}
          products={values?.product_bundle_applicability ?? []}
          onSubmit={(data) => {
            handleUpdateQuantities(data);
          }}
        />
      )}
    </div>
  );
};

export default CategoryBundleTypeTopSection;
