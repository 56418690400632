import {
  APPS_DOMAIN,
  ASGARD_DOMAIN,
  AUTH_DOMAIN,
  DUKAAN_MEDIA_DOMAIN,
  GOOGLE_MAPS_API_KEY,
  isStagingEnv,
  NEWSLETTER_APP_DOMAIN,
  ORDER_DOMAIN,
  ORDER_V2_DOMAIN,
  STORE_DOMAIN,
  STORE_LOCATOR_URL,
  STORE_V2_DOMAIN,
  WHITE_LABEL_WHATSAPP_API_UUID,
} from './constants';

const authAPIUrl = (url) => `${AUTH_DOMAIN}/api/account/seller/${url}`;

const storeAPIUrl = (url) => `${STORE_DOMAIN}/api/store/seller/${url}`;

const storeSellerFrontUrl = (url) => `${STORE_DOMAIN}/api/seller-front/${url}`;

const storeAPIV2Url = (url) => `${STORE_V2_DOMAIN}/api/store/seller/${url}`;

const orderAPIUrl = (url) => `${ORDER_DOMAIN}/api/order/seller/${url}`;
const orderAPIV2Url = (url) => `${ORDER_V2_DOMAIN}/api/order/seller/${url}`;

const productAPIUrl = (url) => `${STORE_DOMAIN}/api/product/seller/${url}`;

const advanceProductAPIUrl = (url) => `${STORE_DOMAIN}/api/product/${url}`;

const productAPIUrlSellerFront = (url) => `${STORE_DOMAIN}/api/product/${url}`;

const couponAPIUrl = (url) => `${STORE_DOMAIN}/api/coupon/seller/${url}`;

const paymentAPIUrl = (url) => `${STORE_DOMAIN}/api/payment/seller/${url}`;

const campaignAPIUrl = (url) => `${STORE_DOMAIN}/api/campaign/seller/${url}`;

const invoiceAPIUrl = (url) => `${STORE_DOMAIN}/api/invoice/seller/${url}`;

const storeLocatorAPIUrl = (url) => `${STORE_LOCATOR_URL}/${url}`;

const sceptreUrl = (url) => `https://sceptre.mydukaan.io/api/sceptre/${url}`;

const metaFieldUrl = (url) => `${STORE_DOMAIN}/api/metafields/seller/${url}`;
const easyEcomUrl = (url) => `${STORE_DOMAIN}/api/easyecom/seller/${url}`;
const vinculumUrl = (url) => `${STORE_DOMAIN}/api/vinculum/seller/${url}`;
const unicommerceUrl = (url) => `${STORE_DOMAIN}/api/unicommerce/seller/${url}`;

const subscriptionPlanAPIUrl = (url) =>
  `${STORE_DOMAIN}/api/plan/seller/${url}`;

export const easyEcomReturnOrderUrl = (uuid) =>
  easyEcomUrl(`${uuid}/ship-easyecom-return/`);

export const vinculumReturnOrderUrl = (uuid) =>
  vinculumUrl(`${uuid}/ship-vinculum-return/`);

export const easyEcomReplacementOrderUrl = (uuid) =>
  easyEcomUrl(`${uuid}/ship-easyecom-replacement/`);
export const approveUnicommerceReturn = (uuid) =>
  unicommerceUrl(`${uuid}/approve-return/`);

export const LOGIN_URL = authAPIUrl('sign-in/');
export const STORE_TOKEN_URL = authAPIUrl('sign-in-store/');

export const EMAIL_LOGIN_URL = authAPIUrl('email-sign-in/v2/');

export const EMAIL_VERIFICATION_URL = authAPIUrl('verify-email/v2/');

export const SEND_EMAIL_VERIFICATION_URL = authAPIUrl(
  'send-verfication-email/'
);

export const SEND_EMAIL_VERIFICATION_FOR_UPDATE_URL = authAPIUrl(
  'send-verification-email-for-update/'
);

export const EMAIL_VERIFICATION_FOR_UPDATE_URL = authAPIUrl(
  'verify-updated-email/'
);

export const REGISTER_BUSINESS_URL = storeAPIUrl('store/');

export const REFRESH_TOKEN_URL = authAPIUrl('token/refresh/');

export const STORE_DETAIL_URL = (storeId) =>
  storeSellerFrontUrl(`store-detail/${storeId}/`);
export const STORE_LIST_URL = storeSellerFrontUrl('store-list/');

export const STORE_CATEGORIES_URL = storeAPIUrl('store-category/');

export const STORE_CATELOGUE_COUNT_URL = storeAPIUrl('catalogue-count/');

export const STORE_ADDITIONAL_INFO_URL = (storeId) =>
  storeAPIUrl(`additional-store-details/${storeId}/`);

export const CONSTANTS_URL = storeAPIUrl('constants/');

export const STORE_ATTRIBUTES_URL = (storeUid) =>
  storeAPIUrl(`${storeUid}/store-attributes/`);

export const GENERATE_PRODUCT_ATTRIBUTES_BULK_UPDATE_EXCEL_TEMPLATE_URL = (
  storeUid
) => storeAPIUrl(`${storeUid}/product-attributes-sheet/`);

export const BULK_UPDATE_PRODUCT_ATTRIBUTES_EXCEL_URL = (storeUid) =>
  productAPIUrl(`${storeUid}/attributes-bulk-upload/`);

export const STORE_URL = (storeUid) => storeAPIUrl(`${storeUid}/store/`);

export const STORE_NEXUS_TAX_URL = (storeUid) =>
  storeAPIUrl(`${storeUid}/store-tax/`);

export const STORE_THEME_DATA_URL = (storeUid) =>
  storeAPIUrl(`${storeUid}/store-theme-data/`);

export const STORE_SERVICE_CHARGE_URL = storeAPIUrl('store-service-charge/');

export const STORE_SERVICE_CHARGE_UPDATE_URL = (chargeId) =>
  storeAPIUrl(`${chargeId}/store-service-charge/`);

export const STORE_ONLINE_STATUS_CHANGE = (storeUid) =>
  storeAPIUrl(`${storeUid}/store-online-status-change/`);

export const GENERATE_STORE_QR_CODE = (storeUid) =>
  storeAPIUrl(`${storeUid}/generate-store-qr-pdf/`);

export const GET_STORE_PREFERENCES = storeAPIUrl(`store-preferences/`);

export const GET_NOTIFICATION_PREFERENCES = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/notification-channel-preference/`);
export const UPDATE_NOTIFICATION_PREFERENCES = (
  storeUuid,
  notificationChannelId
) =>
  storeAPIUrl(
    `${storeUuid}/notification-channel-preference/${notificationChannelId}/`
  );

export const GET_STORE_RETURN_POLICY = storeAPIUrl(`store-return-policy/`);
export const ADD_REASON_TO_STORE_RETURN_POLICY = storeAPIUrl(`add-new-reason/`);
export const GET_STORE_PREFERENCES_NUDGE = (id) =>
  storeAPIUrl(`${id}/store-preferences/nudge/`);

export const ADD_SCARCITY_PRODUCTS = storeAPIUrl(
  `product-scarcity-plugin/settings/`
);

export const GET_FAQS_URL = (storeID) =>
  storeAPIUrl(`faq-plugin/store/${storeID}/`);

export const CREATE_NEW_FAQ_URL = (storeID) =>
  storeAPIUrl(`faq-plugin/store/${storeID}/`);
export const UPDATE_FAQ_URL = (faqID) => storeAPIUrl(`faq-plugin/${faqID}/`);

export const DELETE_FAQ_URL = (faqID) => storeAPIUrl(`faq-plugin/${faqID}/`);

export const GET_PRODUCT_FAQS_URL = (productID) =>
  storeAPIUrl(`faq-plugin/product/${productID}/`);
export const CREATE_NEW_PRODUCT_FAQ_URL = (productID) =>
  storeAPIUrl(`faq-plugin/product/${productID}/`);
export const UPDATE_PRODUCT_FAQ_URL = (faqID) =>
  storeAPIUrl(`faq-plugin/${faqID}/`);
export const DELETE_PRODUCT_FAQ_URL = (faqID) =>
  storeAPIUrl(`faq-plugin/${faqID}/`);

export const GET_CATEGORY_FAQS_URL = (categoryID) =>
  storeAPIUrl(`faq-plugin/product-category/${categoryID}/`);
export const CREATE_CATEGORY_FAQ_URL = (categoryID) =>
  storeAPIUrl(`faq-plugin/product-category/${categoryID}/`);
export const UPDATE_CATEGORY_FAQ_URL = (faqID) =>
  storeAPIUrl(`faq-plugin/${faqID}/`);
export const DELETE_CATEGORY_FAQ_URL = (faqID) =>
  storeAPIUrl(`faq-plugin/${faqID}/`);

export const STOCK_OVERRIDE_URL = storeAPIUrl(
  `continue-selling-when-out-of-stock-plugin/settings/`
);

export const UPDATE_STORE_PREFERENCES = (id) =>
  storeAPIUrl(`store-preferences/${id}/`);

export const BULK_UPDATE_STORE_PREFERENCES = (uuid) =>
  storeAPIUrl(`${uuid}/store-preferences/`);

export const SETUP_WAREHOUSE_URL = storeAPIUrl('store-warehouse/v2/');

export const DUPLICATE_ORDERS_LISTING_URL = storeAPIUrl(
  'duplicate-order-plugin/duplicate-order-list/'
);

export const CHECK_DUPLICATE_ORDERS_URL = (uuid) =>
  storeAPIUrl(`duplicate-order-plugin/check-duplicate-order/${uuid}`);

export const UPDATE_WAREHOUSE_URL = (uuid) =>
  storeAPIUrl(`store-warehouse/v2/${uuid}/`);

export const STORE_DELIVERY_DETAILS_URL = storeAPIUrl(`delivery-charge-rules/`);

export const STORE_STATS_URL = authAPIUrl('account-stats/');

export const UPDATE_ORDERS_NOTES = (uuid) =>
  orderAPIUrl(`${uuid}/update-order-notes/`);

export const UPDATE_ORDER_SPAM_STATUS = (uuid) =>
  orderAPIUrl(`${uuid}/update-spam-status/`);

export const CANCELLED_LINE_ITEMS_DETAILS = orderAPIUrl(
  'cancelled-lineitem-details/'
);

export const ORDER_ACTIVITY_DETAILS_URL = orderAPIUrl('activity-logs/');

export const GET_ORDERS_BY_RTO_STATUS_BULK_SHIP_URL = orderAPIUrl(
  'get-orders-by-risk-for-bulk-ship/'
);

export const ALL_ORDERS_URL = storeSellerFrontUrl(`order-list/`);
export const ORDER_COUNT_STATS = storeSellerFrontUrl('order-count-stats/');
export const ABANDONED_ORDER_COUNT_STATS = orderAPIUrl(
  'abandoned-orders-lifetime-count/'
);

export const ABANDONED_ORDERS_URL = orderAPIUrl('abandoned-orders/');

export const RETURN_ORDERS_URL = orderAPIUrl('order/order-returns/');

export const UPDATE_RETURN_STATUS_URL = (id) => orderAPIUrl(`${id}/returns/`);

export const POPULATE_RTO_SCORE_URL = (uuid) =>
  orderAPIUrl(`${uuid}/populate_rto_score_for_order/`);

export const ORDER_SELF_SHIPPING_URL = (uuid) =>
  orderAPIUrl(`${uuid}/ship-using-external-carrier/`);

export const ORDER_UPDATE_SELF_SHIPMENT_URL = (uuid) =>
  orderAPIUrl(`${uuid}/update-shipment/`);

export const ORDER_LINE_ITEM_FETCH_SHIPPING_URL = orderAPIUrl(
  'get-lineitem-shipping-rates/'
);

export const ORDER_LINE_ITEM_SHIPPING_URL = orderAPIUrl('bulk-ship-lineitems/');

export const ORDER_BUYER_ADDRESS_UPDATE_URL = (uuid) =>
  orderAPIUrl(`${uuid}/buyer-address/`);

export const ADD_BUYER_ADDRESS_CREATE_ORDER = (id) =>
  authAPIUrl(`buyer-address/${id}/`);

export const CREATE_ORDER_BUYER_ADDRESS_UPDATE_URL = (uuid) =>
  authAPIUrl(`${uuid}/buyer-address/`);

export const ORDERS_BULK_SHIPPING_URL = orderAPIUrl(
  `get-bulk-shipping-detail/`
);
export const ORDER_BULK_STATUS_UPDATE_URL = orderAPIUrl(
  `bulk-order-status-update/`
);
export const ORDER_VENDOR_BULK_ACCEPT_URL = orderAPIUrl(`vendor-bulk-accept/`);
export const ORDER_VENDOR_BULK_REJECT_URL = orderAPIUrl(`vendor-bulk-reject/`);

export const ORDERS_STATS_URL = orderAPIUrl(`order-stats/`);
export const ORDERS_STATS_WITH_DURATION_MAPPING_URL = storeSellerFrontUrl(
  `order-stats-with-duration-mapping/`
);

export const GENERATE_ORDERS_PDF_URL = orderAPIUrl(
  'order-report-pdf-generate/'
);

export const GENERATE_COUPONS_REPORT_URL = couponAPIUrl(
  'generate-coupon-export/'
);

export const GENERATE_RETURNS_ORDERS_REPORT_URL = orderAPIUrl(
  'return-report-download/'
);

export const DOWNLOAD_WAREHOUSE_INVENTORY_URL = storeAPIUrl(
  'seller-warehouse-inventory/download/'
);

export const UPLOAD_WAREHOUSE_INVENTORY_URL = storeAPIUrl(
  'seller-warehouse-inventory/upload/'
);

export const GET_WAREHOUSE_INVENTORY_LIST_URL = (uuid) =>
  storeAPIUrl(`seller-warehouse-inventory/${uuid}/`);

export const SET_WAREHOUSE_INVENTORY_LIST_TO_NULL = (uuid) =>
  storeAPIUrl(`set-sku-inventory-infinite/${uuid}/`);

export const GET_INTL_TAX_PLUGINS = orderAPIUrl(`list/tax_plugins/`);
export const SET_PLUGINS_DETAILS = (pluginId) =>
  orderAPIUrl(`tax-plugin/${pluginId}/activate/`);

export const DELIVERY_ORDERS_URL = orderAPIUrl(
  'order/dukaan-delivery-shipments/'
);

export const DELIVERY_ORDERS_STATS_URL = orderAPIUrl(
  'order/dukaan-delivery-shipments-stats/'
);

export const STAFF_SHIPMENT_URL = orderAPIUrl('order/staff-shipments/');

export const DELIVERY_BULK_INVIOCE_DOWNLOAD = orderAPIUrl(
  'order/dukaan-delivery-bulk-invoice-download/'
);

export const DELIVERY_BULK_MANIFEST_DOWNLOAD = orderAPIUrl(
  'order/dukaan-delivery-manifest-download/'
);

export const SETUP_SHIPPO_URL = orderAPIUrl(`shippo/setup-account/`);
export const UNINSTALL_SHIPPO_URL = orderAPIUrl(`shippo/uninstall-account/`);
export const FETCH_INTL_SHIPPING_RATES_URL = orderAPIUrl(`shippo/get-rates/`);
export const SHIP_INTL_ORDER_URL = orderAPIUrl(`shippo/ship/`);
export const SIGNUP_ESHIP_URL = orderAPIUrl(
  `eshipz-plugin/international-signup/`
);
export const ESHIP_FETCH_CARRIERS_URL = orderAPIUrl(
  `eshipz-plugin/get-creds-and-carriers/`
);
export const ESHIP_FETCH_ALL_PARTNERS_URL = orderAPIUrl(
  `eshipz-plugin/get-carriers/`
);

export const PRODUCTS_URL_BASIC = productAPIUrl('product/');

export const PRODUCTS_URL_ADVANCE = (storeId) =>
  advanceProductAPIUrl(`seller/${storeId}/product/v2/`);

export const PRODUCT_TYPES_URL_ADVANCE = (storeId, productType) =>
  advanceProductAPIUrl(
    `seller/${storeId}/product/v2/?product_type=${productType}`
  );

export const PRODUCTS_URL_SELLER_FRONT = (storeId) =>
  storeSellerFrontUrl(`${storeId}/product-list/`);

export const PAGE_PRODUCTS_URL_ADVANCE = (storeId, pageNumber) =>
  advanceProductAPIUrl(`seller/${storeId}/product/v2/?page=${pageNumber}`);

export const PRODUCT_IMAGE_SUGGESTIONS = (query) =>
  `https://projecteagle.mydukaan.io/images?query=${encodeURIComponent(
    query
  )}&gtin=${Date.now()}`;

export const CATALOG_CATAGORY_PRODUCTS = productAPIUrl(`master-category-list/`);
export const UPLOAD_CATALOG_PRODUCTS = productAPIUrl(
  `product-build-catalogue/`
);

export const GENERATE_PRODUCT_PDF = (Uuid) =>
  productAPIUrl(`${Uuid}/generate-product-catalogue/`);

export const GENERATE_CATEGORY_PDF = (Uuid) =>
  productAPIUrl(`${Uuid}/generate-product-category-catalogue/`);

export const PRODUCT_SHORTLINK_URL = (storeUuid, productId) =>
  campaignAPIUrl(`${storeUuid}/product-link/${productId}/`);

export const PRODUCTS_BULK_URL = productAPIUrl(`product-bulk-create/`);
export const PRODUCTS_BULK_SAMPLE_FILE_URL = productAPIUrl(
  `generate-catalogue-upload-file/`
);
export const PRODUCTS_BULK_URL_ADVANCE = (storeId) =>
  advanceProductAPIUrl(`seller/${storeId}/product-bulk-create/v2/`);

export const PRODUCTS_BULK_UPDATE_URL_BASIC =
  productAPIUrl(`product-bulk-update/`);

export const PRODUCTS_BULK_UPDATE_URL_ADVANCE = (storeId) =>
  advanceProductAPIUrl(`seller/${storeId}/product-bulk-update/v2/`);

export const PRODUCTS_BULK_ACTIONS = productAPIUrl(`product-bulk-action/`);

export const CATEGORIES_BULK_ACTIONS_URL = productAPIUrl(
  'product-category-bulk-action/'
);

export const UPLOAD_CSV_URL = productAPIUrl('parse-bulk-upload-file/');

export const UPLOAD_NEW_BULK_PRODUCT_ADVANCE_URL = advanceProductAPIUrl(
  'seller/catalogue-bulk-upload/'
);

export const GET_BULK_PRODUCT_LIST_FILE_URL = advanceProductAPIUrl(
  'seller/generate-catalogue-update-file/'
);

export const GET_BULK_PRODUCT_ATTRIBUTES_FILE_URL = advanceProductAPIUrl(
  'seller/generate-attribute-update-file/'
);

export const UPDATE_BULK_PRODUCT_LIST_FILE_URL = advanceProductAPIUrl(
  'seller/catalogue-bulk-update/'
);

export const CATEGORIES_URL = productAPIUrl(`product-category/`);

export const COUPONS_URL = couponAPIUrl('coupon/v2/');

export const COUPON_URL = (uuid) => couponAPIUrl(`${uuid}/coupon/v2/`);

export const LIST_FREEBIE_PRODUCTS_URL = couponAPIUrl(
  'coupon/v2/freebie-products/'
);

export const CATEGORY_URL = (categoryUid) =>
  productAPIUrl(`${categoryUid}/product-category/`);

export const CATEGORY_BULK_UPDATE_URL = productAPIUrl(`category-bulk-update/`);

export const SINGLE_CATEGORY_URL_BASIC = (categoryId) =>
  productAPIUrl(`product/v2/?category=${categoryId}`);

export const SINGLE_CATEGORY_URL_ADVANCE = ({ categoryId, storeId }) =>
  advanceProductAPIUrl(`seller/${storeId}/product/v2/?category=${categoryId}`);

export const PRODUCT_URL_BASIC = (productUId) =>
  productAPIUrl(`${productUId}/product/`);

export const PRODUCT_URL_ADVANCE = ({ productUId, storeId }) =>
  advanceProductAPIUrl(`seller/${storeId}/product/${productUId}/v2/`);

export const PRODUCT_PARTIAL_UPDATE = (storeId, productUuid) =>
  productAPIUrl(`${storeId}/product/${productUuid}/partial-update/`);

export const MISC_PRODUCT_URL = (productUId) =>
  productAPIUrl(`${productUId}/product-update-misc-data/`);

export const ORDER_URL = (orderUId) => orderAPIUrl(`${orderUId}/order/`);

export const RETURN_REPLACEMENT_DETAILS_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/order-return-replacement-details/`);

export const MARK_REPLACEMENT_COMPLETE_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/mark-replacement-complete/`);

export const REJECT_RETURN_ORDER_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/reject-order-return/`);

export const DELIVERY_DETAILS_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/order-shipment-data/`);

export const ORDER_DUKAAN_SHIPMENT_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/ship-order-using-dukaan-logistic/`);

export const ORDER_DUKAAN_ACCEPTED_SERVICEABLE_URL = orderAPIUrl(
  `pincode-serviceable-orders/`
);

export const ORDER_DUKAAN_MULTIPLE_SHIPMENT_URL = orderAPIUrl(
  `bulk-ship-order-using-dukaan-logistic/`
);

export const ORDER_CANCEL_DUKAAN_SHIPMENT_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/cancel-order-shipment/`);

export const DUKAAN_SHIPMENT_CANCEL_URL = (shipmentUuid) =>
  orderAPIUrl(`${shipmentUuid}/cancel-shipment/`);

export const ORDER_SHIPPING_DETAILS_URL = (orderUuid) =>
  orderAPIUrl(`${orderUuid}/shipping-detail/`);

export const ORDER_DUKAAN_SHIPMENT_LABEL_URL = (shipmentUuid) =>
  orderAPIUrl(`${shipmentUuid}/shipment-invoice-label-link/`);

export const ORDER_DUKAAN_SHIPMENT_TRACKING_URL = (shipmentUuid) =>
  orderAPIUrl(`shipment/${shipmentUuid}/tracking/`);

export const CLUB_ORDER_URL = (tableUuid) =>
  orderAPIUrl(`table/${tableUuid}/club-orders/`);

export const EDIT_ORDER_URL = (orderUId) =>
  orderAPIUrl(`${orderUId}/edit-order/`);

export const CANCEL_LINE_ITEM_URL = orderAPIUrl('cancel-lineitem/');

export const ORDER_PAYMENTS_URL = (orderUuid) =>
  orderAPIUrl(`${orderUuid}/order-payments/`);

export const GENERATE_PAYMENT_LINK = (orderUId) =>
  paymentAPIUrl(`${orderUId}/generate-cod-order-payment-link/?mode=web`);

export const GENERATE_PARTIAL_PAYMENT_LINK = (orderUId) =>
  paymentAPIUrl(`${orderUId}/generate-partial-order-payment-link/?mode=web`);

export const GENERATE_PREORDER_PAYMENT_LINK = (orderUId) =>
  paymentAPIUrl(`${orderUId}/generate-full-pre-order-payment-link/?mode=web`);

export const DUKAAN_DELIVERY_TRANSACTIONS = (storeId) =>
  paymentAPIUrl(`${storeId}/transactions/dukaan-delivery/`);

export const DUKAAN_DELIVERY_STORE_WALLET = (storeUuid) =>
  paymentAPIUrl(`${storeUuid}/dukaan-delivery/store-wallet-data/`);

export const GENERATE_ORDER_RECEIPT = (orderUId) =>
  orderAPIUrl(`${orderUId}/order-receipt-link/`);

export const GENERATE_THERMAL_RECEIPT = (orderUId) =>
  orderAPIUrl(`${orderUId}/order-thermal-receipt-link/`);

export const GENERATE_A4_RECEIPT = (orderUId) =>
  orderAPIUrl(`${orderUId}/order-a4-receipt-link/`);

export const SEND_PAYMENT_LINK_ABANDONED_ORDER = (orderUuid) =>
  paymentAPIUrl(`${orderUuid}/generate-abandoned-order-payment-link/`);

export const UPLOAD_MEDIA_URL = `${DUKAAN_MEDIA_DOMAIN}/api/media/upload/`; // used for all file and image uploads

export const DOWNLOAD_FILE_URL = storeAPIUrl('presigned-download-url/');

export const STORE_ZONAL_URL = storeAPIUrl('store-delivery-zone/');

export const UPLOAD_FILE_URL_V2 =
  'https://api.mydukaan.io/api/product/seller/file/upload/v2/'; // not used anywhere beside http, will remove while cleaning http logic

export const UPDATE_STORE_ZONAL_URL = (zonalId) =>
  storeAPIUrl(`${zonalId}/store-delivery-zone/`);

export const CREATE_TABLE_URL = storeAPIUrl('store-table/');

export const GET_TABLE_URL = storeAPIUrl('store-table/');

export const UPDATE_TABLE_URL = (tableUid) =>
  storeAPIUrl(`${tableUid}/store-table/`);
export const STORE_DELETE_OTP = authAPIUrl('otp-store-delete/');
export const SAVE_PAYMENT_CREDENTIALS_OTP = authAPIUrl(
  'otp-save-payment-credentials/'
);

export const GENERATE_COUPON_PDF_URL = (couponId) =>
  couponAPIUrl(`${couponId}/generate-coupon-pdf/`);

export const ADD_ONS_URL = storeAPIUrl('store-add-on/');
export const ADD_ONS_MODIFY = (addOnUuid) =>
  storeAPIUrl(`${addOnUuid}/store-add-on/`);

export const GET_GRAPHIC_RESOURCE_URL = authAPIUrl(`graphic-resource/`);

export const CUSTOMER_URL = storeAPIUrl('store-buyer/');
export const ADD_CUSTOMER_URL = campaignAPIUrl('store-lead/v2/');
export const EXPORT_CUSTOMER_LIST_URL = storeAPIUrl('store-buyer/export/');
export const CUSTOMER_BULK_URL = campaignAPIUrl('store-lead-bulk-v2/');
export const CUSTOMER_COUNT_URL = campaignAPIUrl('store-lead-count/');
export const ADD_GMAIL_CUSTOMER_LIST = campaignAPIUrl('store-lead-import/');
export const UPDATE_CUSTOMER_NOTES = (customerId) =>
  orderAPIUrl(`${customerId}/update-storelead-notes/`);

export const TRANSACTIONS_URL = (storeUuid) =>
  paymentAPIUrl(`store/${storeUuid}/v3/transactions/`);
export const DIRECT_TRANSACTIONS_URL = paymentAPIUrl(
  'direct-to-seller-transactions/'
);

// dukaan pay
export const DUKAAN_PAY_TRANSACTIONS_URL = paymentAPIUrl(
  `dukaan-pay-transactions/`
);
export const DUKAAN_PAY_STATS_URL = paymentAPIUrl(`dukaan-pay-summary/`);

export const EXPORT_PAYMENT_DATA = (storeUuid) =>
  paymentAPIUrl(`${storeUuid}/transactions/export/`);

export const WALLET_URL = (storeUuid) =>
  paymentAPIUrl(`store/${storeUuid}/v3/store-wallet-data/`);

export const DIRECT_STATS_URL = paymentAPIUrl('direct-to-seller-stats/');

export const GENRATE_KYC_OTP_URL = authAPIUrl('v3/generate-kyc-otp/');
export const VERIFY_KYC_OTP_URL = authAPIUrl('v3/complete-kyc/');

export const CREDIT_TRANSACTIONS_URL = (storeUuid) =>
  paymentAPIUrl(`${storeUuid}/store-wallet-transaction/`);

export const FETCH_STRIPE_KEY = storeAPIUrl('stripe-pg-key/');

export const CREDIT_TRANSACTION_DETAILS_URL = (storeUuid, txnUUid) =>
  paymentAPIUrl(`${storeUuid}/store-wallet-transaction/${txnUUid}`);

export const CREDIT_DISCOUNTS_URL = storeAPIUrl('dukaan-credits-discount');

export const CREATE_PREMIUM_ORDER = paymentAPIUrl('subscribe/store-plan/');
export const CREATE_RENEW_ORDER = paymentAPIUrl('renew/store-plan/');
export const UPGRADE_PREMIUM_ORDER = paymentAPIUrl(
  'subscribe/upgrade-store-plan/'
);
export const STORE_PLAN_BALANCE = paymentAPIUrl(
  'subscribe/store-plan-balance/'
);

export const CREATE_CREDITS_ORDER = (storeUuid) =>
  paymentAPIUrl(`${storeUuid}/store-wallet-payment-link-gen/`);

export const CREATE_STORE_PAYMENT_DETAIL = storeAPIUrl('store-payment-detail/');

export const UPDATE_STORE_PAYMENT_DETAIL = (paymentDetailUuid) =>
  storeAPIUrl(`${paymentDetailUuid}/store-payment-detail/`);

export const CREATE_MANUAL_PAYMENT_METHOD = storeAPIUrl(
  'manual-payment-method/'
);

export const UPDATE_MANUAL_PAYMENT_METHOD = (paymentMethodUuid) =>
  storeAPIUrl(`manual-payment-method/${paymentMethodUuid}/`);

export const DELETE_MANUAL_PAYMENT_METHOD = (paymentMethodUuid) =>
  storeAPIUrl(`manual-payment-method/${paymentMethodUuid}/`);

export const ALL_PLUGINS_URL = `${APPS_DOMAIN}/api/v2/apps/`;
export const ALL_ACTIVE_PLUGINS_URL = `${APPS_DOMAIN}/api/v2/activeapps/`;

export const APPS_UPLOAD_IMAGE_URL = `${APPS_DOMAIN}/api/v2/img/upload`;
export const PROCESS_BACKGROUND_REMOVER_URL = `https://bgapi.mydukaan.io/url`;

export const NEWSLETTER_APP_CONFIG_URL = `${NEWSLETTER_APP_DOMAIN}/api/config`;

export const GET_ORDER_FORM_FIELDS = storeAPIUrl('store-order-form/');
export const GET_ORDER_FORM_FIELD = (fieldUuid) =>
  storeAPIUrl(`${fieldUuid}/store-order-form/`);
export const CREATE_ORDER_FORM_FIELD = storeAPIUrl('store-order-form/');
export const UPDATE_ORDER_FORM_FIELD = (fieldUuid) =>
  storeAPIUrl(`${fieldUuid}/store-order-form/`);
export const DELETE_ORDER_FORM_FIELD = (fieldUuid) =>
  storeAPIUrl(`${fieldUuid}/store-order-form/`);

export const GET_STORE_ORDER_SETTINGS = storeAPIUrl('store-order-settings/');
export const UPDATE_STORE_ORDER_SETTINGS = (uuid) =>
  storeAPIUrl(`store-order-settings/${uuid}/`);

export const PRODUCT_SKU_PAYMENT_MODE_PLUGIN_MAPPING = storeAPIUrl(
  'product-sku-payment-mode-plugin/mapping/'
);

export const PINCODE_CHECKER_DATA = storeAPIUrl(
  'pincode-checker-plugin/pincode-data/'
);

export const UNLOCK_ACCOUNT_PAYMENT_LINK_GENERATION = paymentAPIUrl(
  'store-seller-web-payment-link-gen/'
);

export const PREMIUM_DOMAIN_PURCHASE = paymentAPIUrl(
  'razorpay/premium-custom-domain-purchase/'
);

export const PREMIUM_DOMAIN_PURCHASE_PADDLE = paymentAPIUrl(
  'premium-custom-domain-purchase/'
);

export const STORE_ORDERS_OVERVIEW_DATA_URL = orderAPIUrl(
  'order-sales-graph-data/'
);

export const STORE_VIEW_OVERVIEW_DATA_URL = storeAPIUrl(
  'store-views-graph-data/'
);

export const PADDLE_INTL_SUBSCRIPTION_URL = subscriptionPlanAPIUrl(
  'paddle/store-plan-buyer-order-create/'
);

export const WHATSAPP_BUSINESS_ACCOUNT_PLANS = subscriptionPlanAPIUrl(
  `seller-add-on-plan/${WHITE_LABEL_WHATSAPP_API_UUID}/`
);

export const WHATSAPP_BUSINESS_ACCOUNT_PAYMENT_URL = paymentAPIUrl(
  `subscribe/seller-add-on-plan/`
);

export const WHATSAPP_ACCOUNT_PLUGIN_STATUS = subscriptionPlanAPIUrl(
  `seller-add-on-plan/${WHITE_LABEL_WHATSAPP_API_UUID}/subscription/`
);

export const SUBSCRIPTION_HISTORY_URL = (storeId) =>
  paymentAPIUrl(`${storeId}/store-payment-history/`);

export const UPDATE_STORE_INFO = (storeUUID) =>
  paymentAPIUrl(`${storeUUID}/store-info/`);

export const UPDATE_STORE_META = (storeId) =>
  storeAPIUrl(`${storeId}/update-store-meta/`);

export const UPDATE_STORE_MISC_DATA = (storeId) =>
  storeAPIUrl(`${storeId}/store-meta-update-misc-data/`);

export const GENERATE_SUBSCRIPTION_INVOICE = (storePaymentId) =>
  paymentAPIUrl(`${storePaymentId}/generate-subscription-invoice-pdf/`);

export const AUTH_CALLBACK_URL_MAP = {
  google: authAPIUrl('firebase/google-sign-in-callback/'),
  facebook: authAPIUrl('firebase/facebook-sign-in-callback/'),
};

export const RESET_PASSWORD_URL = authAPIUrl('reset-password/');

export const SEND_RESET_PASSWORD_URL = authAPIUrl('send-password-reset-mail/');

export const VERIFY_EMAIL_URL = authAPIUrl('check-email/');

export const UPDATE_NUMBER_URL = authAPIUrl('update-phone-number/');
export const PAGES_URL = storeAPIUrl('store-page/');
export const CHECK_PAGE_LINK_EXISTS_URL = storeAPIUrl('store-page/link-check/');
export const SINGLE_PAGE_URL = (pageUuid) =>
  storeAPIUrl(`${pageUuid}/store-page/`);

export const MENU_ITEMS = (sellerId) =>
  storeAPIUrl(`${sellerId}/store-menu-item/`);

export const CUSTOM_DOMAIN_URL = storeAPIUrl('custom-domain-search/');
export const DELINK_DOMAIN_URL = storeAPIUrl('remove-custom-domain/');

export const CUSTOM_DOMAIN_PURCHASE_URL = (storeId) =>
  storeAPIUrl(`${storeId}/custom-domain-purchase/`);

export const EXTERNAL_DOMAIN_URL = (storeId) =>
  storeAPIUrl(`${storeId}/connect-external-custom-domain/`);

export const VERIFY_EXTERNAL_DOMAIN_URL = storeAPIUrl(
  `verify-external-custom-domain/`
);

export const CLEAR_CUSTOM_DOMAIN_BANNER_URL = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/clear-domain-deployment-banner/`);

export const ONBOARDING_API_URL = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/store-onboarding-data/`);

export const STORE_PLANS_URL = storeAPIUrl('plans/v2/');
export const STORE_PLANS_V3_URL = storeAPIUrl('plans/v3/');
export const STORE_PLAN_FEATURES_URL = storeAPIUrl('plan-features/');
export const DUE_INVOICE = storeAPIUrl('last-due-invoice/');
export const INVOICES_LISTING = storeAPIUrl('invoice/');
export const TRANSACTION_FEE_FOR_ORDERS_LSITING = (invoiceUuid) =>
  storeAPIUrl(`invoice-group/${invoiceUuid}/list/`);
export const INVOICE_PAYMENT_URL = (invoiceUuid) =>
  paymentAPIUrl(`invoice/${invoiceUuid}/`);

export const START_PREMIUM_TRIAL_URL = storeAPIUrl(
  'plans/start-premium-trial/'
);
export const STORE_PLAN_DETAILS = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/store-plan/?v2=true`);

export const STORE_PLAN_STATS_URL = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/store-plan-stats/?v2=true`);

export const STORE_THEME = (sellerId) =>
  storeAPIUrl(`${sellerId}/store-theme-data/`);

export const GET_STORE_THEMES = storeAPIUrl('themes/?web=true');
export const GET_ACTIVE_THEME = (sellerId) =>
  storeAPIUrl(`${sellerId}/store-theme/`);
export const UPDATE_STORE_THEME = (sellerId) =>
  storeAPIUrl(`${sellerId}/store-theme/`);

export const PURCHASE_THEME = (sellerId) =>
  storeAPIUrl(`${sellerId}/store-theme/purchase/`);

export const PURCHASE_INTERNATIONAL_THEME = (sellerId) =>
  paymentAPIUrl(`${sellerId}/paddle/store-theme-purchase/`);

export const STORE_TIMINGS_URL = storeAPIUrl('store-timing/');
export const NOTIFY_URL = storeAPIUrl('notify/');

export const STORE_BANNERS = storeAPIUrl('store-banner/v2/');
export const STORE_BANNER_BY_ID = (uuid) =>
  storeAPIUrl(`${uuid}/store-banner/`);
export const PIN_CODE_DATA_API = (pin) =>
  `${STORE_DOMAIN}/api/store/buyer/fetch-pincode-data/?pin=${pin}`;

export const REVIEWS = productAPIUrl('product-review/');

export const SINGLE_REVIEW_URL = (reviewId) =>
  productAPIUrl(`product-review/${reviewId}/`);

export const REVIEW_SETTINGS_URL = (storeId) =>
  storeAPIUrl(`${storeId}/product-review-config/`);

export const REVIEW_BULK_ACTIONS_URL = productAPIUrl(
  `product-review/bulk-actions/`
);

export const REVIEW_BULK_UPLOAD_URL = productAPIUrl(
  `product-review/bulk-review/`
);

export const REVIEW_BULK_DOWNLOAD_URL = productAPIUrl(
  `product-review/bulk-review/`
);

export const WAREHOUSE_URL = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/store-warehouse/`);

export const CAMPAIGN_URL = (storeUuid) =>
  campaignAPIUrl(`${storeUuid}/campaign/`);

export const CAMPAIGN_DETAILS_URL = (storeUuid, campaignId) =>
  campaignAPIUrl(`${storeUuid}/campaign/${campaignId}/`);

export const CAMPAIGN_STATS_URL = (storeUuid) =>
  campaignAPIUrl(`${storeUuid}/campaign/stats/`);

export const CAMPAIGN_SEND_TEST_MESSAGE_URL = (storeUuid) =>
  campaignAPIUrl(`${storeUuid}/campaign/test-message/`);

export const CAMPAIGN_ESTIMATE_URL = (storeUuid) =>
  campaignAPIUrl(`${storeUuid}/campaign/estimates/`);

export const CAMPAIGN_TEMPLATES_URL = (storeUuid) =>
  campaignAPIUrl(`${storeUuid}/campaign/templates/`);

export const CAMPAIGN_TEMPLATES_BY_CHANNEL_URL = (storeUuid, channelId) =>
  campaignAPIUrl(`${storeUuid}/campaign/templates/${channelId}/`);

export const CAMPAIGN_CHANNELS_URL = campaignAPIUrl(`channel/`);

export const TUTORIALS_URL = storeAPIUrl(`store-videos/`);

export const CAMPAIGN_ONLINE_PAYMENT_URL = (storeUuid) =>
  paymentAPIUrl(`${storeUuid}/create-campaign-payment-link/`);

export const WHATSAPP_OTP_URL = authAPIUrl('whatsapp-chat-support/');

export const FETCH_STAFF_ACCOUNTS_URL = storeAPIUrl('staff/');
export const CREATE_STAFF_ACCOUNTS_URL = storeAPIUrl('staff/');
export const ADD_STAFF_PAYMENT_URL = paymentAPIUrl('staff/');
export const MODIFY_STAFF_ACCOUNT_URL = (uuid) => storeAPIUrl(`staff/${uuid}/`);
export const STAFF_ACCEPT_INVITE_URL = (uuid) =>
  storeAPIUrl(`staff/accept-invite/${uuid}/`);
export const REVOKE_STAFF_INVITE = storeAPIUrl('staff/revoke-invite/');
export const VERIFY_STAFF_INVITE = storeAPIUrl('staff/verify-invite/');

export const REFERRAL_CODE_URL = storeAPIUrl(`referral-code/`);
export const SUCCESSFULL_REFERRALS_LIST = storeAPIUrl(`store-referral/`);
export const VERIFY_REFERRAL_CODE = storeAPIUrl(`verify-referral-code/`);
export const CREDITS_PLAN_DETAILS = storeAPIUrl(`plan-reward-detail/`);

export const ANNOUNCEMENTS_URL = storeAPIUrl(`announcement/`);

export const NOTIFICATIONS_LIST_URL = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/dashboard-alert/`);
export const MARK_NOTIFICATION_READ_URL = (notificationUuid) =>
  storeAPIUrl(`mark-dashboard-alert-as-read/${notificationUuid}/`);

export const ACCOUNT_INVOICES_API_URL = (storeUUID) =>
  invoiceAPIUrl(`${storeUUID}/delivery-invoices/`);
export const GET_STORE_CHECKOUT_PREFERENCES = (storeId) =>
  storeAPIUrl(`${storeId}/store-preferences/checkout/`);
export const GET_STORE_CHECKOUT_FORM_DATA = (storeId) =>
  storeAPIUrl(`${storeId}/checkout-form/`);
export const GET_STORE_CHECKOUT_FORM_DATA_COUNTRY = (storeId, countryCode) =>
  storeAPIUrl(`${storeId}/checkout-form/?country_code=${countryCode}`);

export const GET_RECOVERY_ABANDONED_CART = (storeId) =>
  storeAPIUrl(`${storeId}/store-preferences/abandoned-cart/`);

export const UPDATE_STORE_CHECKOUT_PREFERENCES = (storeId) =>
  storeAPIUrl(`${storeId}/store-preferences/`);

export const GET_SELLER_PLAN = storeAPIUrl(`seller-plan/`);

export const PROOF_OF_DELIVERY = paymentAPIUrl('proof-of-delivery/');

export const STORE_RENEWAL_PLAN_DETAILS = subscriptionPlanAPIUrl(
  'renew-plan/get-renewal-price-data/'
);

export const POST_STORE_RENEWAL_PLANS = subscriptionPlanAPIUrl('renew-plan/');

export const POST_DOMAIN_RENEWAL = paymentAPIUrl(
  'premium-custom-domain-renew/'
);

export const POST_CONTINUE_DEFAULT_DOMAIN = storeAPIUrl(
  'remove-custom-domain/'
);

export const PICKUP_FAILED_SHIPMENTS_URL = orderAPIUrl(
  'order/dukaan-delivery-pickup-failed-shipments/'
);

export const REVIEWS_IMPORTER_PLUGIN = productAPIUrl('product-review/');
export const POST_DELETE_STORES = storeAPIUrl('app-sumo/downgrade/store/');

export const EMAIL_FORWARDING = storeAPIUrl('email-forwarding/');
export const DELETE_EMAIL_FORWARDING = (id) =>
  storeAPIUrl(`email-forwarding/${id}/`);

export const GET_DOMAIN_PRICE = subscriptionPlanAPIUrl(
  'custom-domain-renewal-price/'
);
export const INTL_PLAN_SUBSCRIBE = paymentAPIUrl('subscribe/store-plan/');

export const CANCEL_SUBSCRIPTION_URL = paymentAPIUrl('cancel-subscription/');

export const SELLER_PAYMENT_HISTORY = (storeId) =>
  paymentAPIUrl(`${storeId}/seller-payment-history/`);

export const STORE_IMPORTER_URL = `${ASGARD_DOMAIN}/import-store/`;
export const ADVANCE_STORE_IMPORTER_URL = storeAPIUrl('advance-importer/');

export const GUMROAD_IMPORTER_URL = storeAPIUrl('gumroad-plugin/import/');

export const ABANDONED_CART_CHECKOUT_TEMPLATES = (
  storeUuid,
  channelId,
  queryParam
) =>
  campaignAPIUrl(
    `${storeUuid}/campaign/templates/${channelId}/?${queryParam}&offer_type=cart_abandoned`
  );

export const STORE_LOCATOR_BULK_UPLOAD = storeLocatorAPIUrl('bulk/');

export const OFFLINE_STORE_URL = storeLocatorAPIUrl('stores/');

export const INDIVIDUAL_OFFLINE_STORE = (storeId) =>
  storeLocatorAPIUrl(`stores/${storeId}`);

export const SEARCH_OFFLINE_STORES = storeLocatorAPIUrl('search');

export const GET_SIZE_CHARTS_PLUGIN = (storeId) =>
  productAPIUrl(`${storeId}/size-chart/`);

export const ANALYTICS_OVERVIEW = (storeId) =>
  orderAPIUrl(`analytics/${storeId}/orders-overview/`);

export const ANALYTICS_BUSINESS_OVERVIEW = (storeId) =>
  orderAPIUrl(`analytics/${storeId}/order-distribution/`);

export const ANALYTICS_UTM_WISE_SESSIONS = storeAPIUrl(
  `store-analytics/utmwise-session-breakdown/`
);

export const ANALYTICS_STORE_CONVERSION = (storeId) =>
  storeAPIUrl(`${storeId}/store-analytics/store-conversions/`);

export const ANALYTICS_AVG_STORE_SESSIONS = (storeId) =>
  storeAPIUrl(`${storeId}/store-analytics/avg-store-conversion-sessions/`);

export const ANALYTICS_SALES_BREAKDOWN_API = (storeId) =>
  orderAPIUrl(`analytics/${storeId}/gross-sale-breakdown/`);

export const ANALYTICS_STORE_AVG_VIEWS = (storeId) =>
  storeAPIUrl(`${storeId}/store-analytics/avg-views-per-day/`);

export const ANALYTICS_STORE_AVG_SESSIONS = (storeId) =>
  storeAPIUrl(`${storeId}/store-analytics/avg-sessions-per-day/`);

export const ANALYTICS_STORE_RETURNING_BUYERS = (storeId) =>
  storeAPIUrl(`${storeId}/store-analytics/returning-user/`);

export const ANALYTICS_STORE_VIEWS = (storeId) =>
  storeAPIUrl(`${storeId}/store-analytics/avg-store-views/`);

export const ANALYTICS_PRODUCT_OVERVIEW = (storeId) =>
  productAPIUrl(`${storeId}/product-analytics/view-per-product/`);

export const ANALYTICS_CATEGORY_OVERVIEW = (storeId) =>
  productAPIUrl(`${storeId}/product-analytics/view-per-category/`);

export const ANALYTICS_PRODUCT_VIEW_DATA = (storeId) =>
  productAPIUrl(`${storeId}/product-analytics/avg-view-per-product/`);

export const ANALYTICS_CATEGORY_BY_SALES = (storeId) =>
  productAPIUrl(`${storeId}/product-analytics/top-categories-by-sales/`);

export const ANALYTICS_TOP_PRODUCTS_BY_UNITS_SOLD = (storeId) =>
  productAPIUrl(`${storeId}/product-analytics/top-products-by-unit/`);

export const POST_CORE_PLUGIN = (pluginId) =>
  storeAPIUrl(`activate-plugin/${pluginId}/`);

export const DEACTIVATE_CORE_PLUGIN = (pluginId) =>
  storeAPIUrl(`deactivate-plugin/${pluginId}/`);

export const GET_CORE_PLUGIN_CONFIG = (pluginId) =>
  storeAPIUrl(`get-plugin-config/${pluginId}/`);

export const DELETE_CORE_PLUGIN = (pluginId) =>
  storeAPIUrl(`delete-plugin/${pluginId}/`);

export const GET_WISHLIST = storeAPIUrl('wishlist-data/');

export const GET_WISHLIST_PLUGIN_STATUS = storeAPIUrl('wishlist-status/');

export const ACTIVATE_WISHLIST = storeAPIUrl('activate-wishlist/');

export const EXPORT_WISHLIST_URL = storeAPIUrl('export-wishlist/');

export const WISHLIST_NOTIFY_CUSTOMERS_URL = storeAPIUrl(
  'wishlist-send-customer-notifications/'
);

export const HSN_CODE_FINDER =
  'https://39e76gbnc0.execute-api.ap-south-1.amazonaws.com/Prod/tools/';

export const GOOGLE_PLACES_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=geometry,places`;

export const CHECK_IP_API = 'https://apiv1.bot9.ai/api/geoip/details';

export const LINK_MINK_API = 'https://app.linkmink.com/api/v0.1.0';

export const ACTIVATE_WHITELABEL_WHATSAPP = subscriptionPlanAPIUrl(
  `seller-add-on-plan/${WHITE_LABEL_WHATSAPP_API_UUID}/activate/`
);

export const REQUEST_CALLBACK_WHATSAPP_WHITE_LABEL = subscriptionPlanAPIUrl(
  'seller-add-on-plan/whatsapp-number/request-callback/'
);

export const GET_WHITE_LABEL_WHATSAPP_NUM = subscriptionPlanAPIUrl(
  'seller-add-on-plan/whatsapp-number/'
);

export const WHITELABEL_WHATSAPP_TOP_UP = (id) =>
  subscriptionPlanAPIUrl(
    `seller-add-on-plan/whatsapp-number/${id}/add-credits/`
  );

export const WHITELABEL_WHATSAPP_TRANSACTIONS = (id) =>
  subscriptionPlanAPIUrl(
    `seller-add-on-plan/whatsapp-number/${id}/whatsapp-credit-transactions/`
  );
export const PAYOUT_API_URL = paymentAPIUrl('payout/');
export const PAYOUT_DETAILS_API_URL = (uuid) =>
  paymentAPIUrl(`payout/${uuid}/orders/`);
export const TRANSACTIONS_URL_DUKAAN_DELIVERY = (storeUUID) =>
  paymentAPIUrl(`store/${storeUUID}/v3/transactions/`);

export const RECOMMENDATIONS = storeAPIUrl('recommendations/');

export const ALL_SHORTCUTS = storeAPIUrl('shortcuts/');

export const ACTIVE_SHORTCUTS = storeAPIUrl('store-shortcuts/');
export const INAI_PAYMENT_GATEWAY = (uuid) =>
  storeAPIUrl(`${uuid}/inai-payment-gateway/`);

export const REMOVE_PAYPAL_PAYMENT_PROVIDER = (uuid) =>
  storeAPIUrl(`${uuid}/remove-paypal-pay-key/`);

export const PAYMENT_PROVIDER_DISCOUNT = storeAPIUrl(
  `store-payment-discount-providers-is-active-status/`
);

export const LOYALTY_POINTS_PLUGIN_URL = storeAPIUrl(
  'customer-wallet-plugin/settings/'
);

export const LOYALTY_POINTS_SAMPLE_FILE = storeAPIUrl(
  'store-buyer/get-customer-wallet-bulk-upload-sample-file/'
);

export const UPDATE_LOYALTY_POINTS_CSV = storeAPIUrl(
  'store-buyer/customer-wallet-bulk-upload/'
);

export const GET_CUSTOMER_DETAILS = (id) =>
  campaignAPIUrl(`store-lead/v2/store-lead-data/${id}/`);

export const LOGIN_AS_CUSTOMER = (buyerId) =>
  campaignAPIUrl(`${buyerId}/buyer-token/`);

export const MANUAL_ORDER_CALCULATION = (storeUUID) =>
  orderAPIUrl(`${storeUUID}/order-calculations/`);

export const RESEND_MANUAL_ORDER_PAYMENT_LINK = (orderUuid) =>
  orderAPIUrl(`${orderUuid}/resend-manual-order-payment-link/`);

export const INITIATE_ORDER_REFUND = orderAPIUrl('initiate-order-refund/');

export const CREATE_MANUAL_ORDER = orderAPIUrl('order/');

export const ORDER_SOURCES = orderAPIUrl('order-source/');

export const BUYER_ADDRESSES = (storeUUID, buyerID) =>
  storeAPIUrl(`${storeUUID}/buyer/${buyerID}/`);

export const STORE_TAGS = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/store-tags/`);

export const LIST_APPLIED_TAGS = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/tags/`);

export const REMOVE_STORE_TAGS = (storeUuid, tagUuid) =>
  storeAPIUrl(`${storeUuid}/tags/${tagUuid}/`);

export const PRODUCTS_BRAND = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/product-brands/`);

export const SPECIFIC_BRAND_URL = (storeUuid, productId) =>
  storeAPIUrl(`${storeUuid}/product-brands/${productId}/`);

export const INITIAL_RETURN_REFUND = (returnUuid) =>
  orderAPIUrl(`${returnUuid}/initiate-return-refund/`);

export const GET_WEBHOOKS = storeAPIUrl(`topic-webhook/settings/`);
export const GET_WEBHOOK_TOPICS = storeAPIUrl(`topic-webhook/`);
export const CREATE_WEBHOOK = storeAPIUrl(`topic-webhook/settings/`);
export const TEST_WEBHOOK = orderAPIUrl(`topic-webhook/test/`);
export const DELETE_WEBHOOK = (webhookId) =>
  storeAPIUrl(`topic-webhook/${webhookId}/`);

export const SETUP_SHIPPING_PARTNERS = orderAPIUrl(
  `dd-custom-account/connect-carriers/`
);

export const START_SHIPPING_SETUP = orderAPIUrl(
  'dd-custom-account/start-setup/'
);

export const FETCH_CONNECTED_PARTNERS = orderAPIUrl(
  `dd-custom-account/connected-carriers/`
);

export const FETCH_ALL_CARRIERS = orderAPIUrl(
  `dd-custom-account/available-carriers/`
);

export const UPDATE_SHIPPING_PARTNERS = (uuid) =>
  orderAPIUrl(`dd-custom-account/update-connected-carriers/${uuid}/`);

export const CARRIER_SERVICE_PRIORITY = orderAPIUrl(
  `dd-custom-account/account-carrier-service-ordering/`
);

export const THEME_FILES = (themeKey) => sceptreUrl(themeKey);

export const METAFIELDS_LIST = metaFieldUrl(`metafield/`);
export const SKU_METAFIELDS_LIST = metaFieldUrl(`productsku/metafields/`);
export const ORDER_METAFIELDS_LIST = metaFieldUrl(`order/metafields/`);
export const CATEGORY_METAFIELDS_LIST = metaFieldUrl(
  `productcategory/metafields/`
);
export const CATEGORY_METAFIELDS_RESOURCE_VALUE_LIST = metaFieldUrl(
  `productcategory/resource-metafield-value/`
);
export const ORDER_METAFIELDS_RESOURCE_VALUE_LIST = metaFieldUrl(
  `order/resource-metafield-value/`
);

export const PRODUCT_METAFIELDS_LIST = metaFieldUrl(
  `product/resource-metafield-value/`
);
export const PRODUCT_SKU_METAFIELDS_LIST = metaFieldUrl(
  `productsku/resource-metafield-value/`
);
export const APPLICABLE_METAFIELDS = metaFieldUrl(`product/metafields/`);

export const META_FIELD_TYPE_LIST = metaFieldUrl(`metafield-types/`);

export const REORDER_METAFIELDS = metaFieldUrl(`metafields-reorder/`);

export const STORE_LANGUAGES = storeAPIUrl('store-language/');

export const AVAILABLE_LANGUAGES = storeAPIUrl('available-language/');

export const UPDATE_STORE_LANGUAGE = (languageUuid) =>
  storeAPIUrl(`store-language/${languageUuid}/`);

export const LANGUAGE_BULK_UPDATE_FILE = storeAPIUrl(
  'store-language/bulk-update/'
);

export const ZAPIER_HOOK =
  'https://hooks.zapier.com/hooks/catch/13494480/bpjerff/';

export const VENDOR_COMMISSION_LIST = orderAPIUrl('vendor-commission/');
export const VENDOR_COMMISSION_REPORT_EXPORT = orderAPIUrl(
  'vendor-commission/export/'
);
export const BACKGROUND_TASK_POLLING_URL = (taskId) =>
  `${STORE_DOMAIN}/api/shared/background-task/${taskId}/`;

export const ERROR_REPORTING = `${STORE_DOMAIN}/api/errors/report/`;

export const IMPORT_STORE_POLLING_URL = (storeId) =>
  `${ASGARD_DOMAIN}/import-store/${storeId}/status/`;

export const SEO_TAG_GENERATOR_URL = `https://ymzyyxcjo7rbpcpacqgapr7z6a0kygkb.lambda-url.ap-southeast-1.on.aws/`;

export const BACKGROUND_TASK = storeAPIUrl('background-task/');
export const BACKGROUND_TASK_PROGRESS = storeAPIUrl(
  'background-task/progress-overview/'
);
export const BACKGROUND_TASK_RETRY = (taskId) =>
  storeAPIUrl(`background-task/${taskId}/retry/`);

export const SAVE_PAYMENT_GATEWAY_CREDS = (storeUuid, gatewayId) =>
  storeAPIUrl(`${storeUuid}/save-payment-gateway-creds/${gatewayId}/`);

export const SAVE_RAZORPAY_GATEWAY_CREDS = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/save-razor-pay-pg-key/`);

export const GET_PAYMENT_GATEWAY_CREDS = (storeUuid, gatewayuuid) =>
  storeAPIUrl(`${storeUuid}/payment-gateway/${gatewayuuid}/`);

export const GET_CASHFREE_ACCOUNT_STATUS = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/get-cashfree-account-status/`);

export const CREATE_CASHFREE_MERCHANT_ACCOUNT = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/create-cashfree-merchant-account/`);

export const GET_CASHFREE_ONBOARDING_LINK = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/get-onboarding-embedded-link/`);

export const ACTIVATE_PAYMENT_GATEWAY = (storeUuid, gatewayId) =>
  storeAPIUrl(`${storeUuid}/activate-payment-gateway/${gatewayId}/`);

export const DEACTIVATE_PAYMENT_GATEWAY = (storeUuid, gatewayId) =>
  storeAPIUrl(`${storeUuid}/deactivate-payment-gateway/${gatewayId}/`);

export const DELETE_PAYMENT_GATEWAY = (storeUuid, gatewayId) =>
  storeAPIUrl(`${storeUuid}/delete-payment-gateway/${gatewayId}/`);

export const CREATE_MEDIA = storeAPIUrl('bulk-media-upload/');

export const GET_MEDIA = storeAPIUrl('media/');

export const UPDATE_DELETE_MEDIA = (uuid) => storeAPIUrl(`${uuid}/media/`);

export const GET_POST_REDIRECT_URL = storeAPIUrl('store-custom-link/');

export const DELETE_PATCH_REDIRECT_URL = (uuid) =>
  storeAPIUrl(`${uuid}/store-custom-link/`);

// package urls
export const PACKAGE_URL = (storeUuid) =>
  storeAPIUrl(`${storeUuid}/shipping-package/`);

export const PACKAGE_RESOURCE_URL = (storeUuid, packageUuid) =>
  storeAPIUrl(`${storeUuid}/shipping-package/${packageUuid}/`);
