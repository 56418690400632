import { lazy } from 'react';
import {
  StaffAccountAccessRevoked,
  StaffAccountError,
  StaffAccountSuccess,
} from './Account/StaffAccountVerified';
import { STAFF_ROLES } from './Account/constants';

import {
  rootUrls,
  authUrls,
  catalogueUrls,
  accountUrls,
  marketingUrls,
  manageUrls,
  restaurantUrls,
  pluginsUrls,
  testModalsUrl,
  pagesUrls,
  internationPaymentsUrl,
  appearanceUrls,
  tutorialUrls,
  campaignUrls,
  referralUrls,
  analyticsUrl,
  blogUrls,
  mediaLibraryUrls,
} from './Urls';
import Reseller from './Layout/Reseller';
import OrderDetails from './Orders/OrderDetails';
import InternationalLogin from './InternationalLogin';

const Home = lazy(() => import('./Home'));
const Orders = lazy(() => import('./Orders'));
const ReturnedOrders = lazy(() => import('./Orders/ReturnedOrders'));
const AbandonedOrders = lazy(() => import('./Orders/AbandonedOrders'));
const AbandonedOrdersAdvanceSettings = lazy(() =>
  import('./Orders/AbandonedOrdersAdvanceSettings')
);
const CommissionReport = lazy(() => import('./Orders/CommissionReport'));
const SuccessRecoveryMessageBroadcast = lazy(() =>
  import('./Orders/SuccessRecoveryMessageBroadcast')
);
const ShipMultipleOrders = lazy(() => import('./Orders/ShipMultipleOrders'));
const MultipleDeliveryPartners = lazy(() =>
  import('./Orders/MultipleDeliveryPartners')
);
const ConfirmMultipleOrders = lazy(() =>
  import('./Orders/ConfirmMultipleOrders')
);
const EditOrder = lazy(() => import('./Orders/EditOrder'));
const ConfirmDeliveryCost = lazy(() => import('./Orders/ConfirmDeliveryCost'));
const AddShipmentWeight = lazy(() =>
  import('./Orders/components/AddShipmentWeight')
);
const SelectShipmentPartner = lazy(() =>
  import('./Orders/components/SelectShipmentPartner')
);
const SelectReplacementShippingPartner = lazy(() =>
  import('./Orders/components/SelectReplacementShippingPartner')
);
const IntlSelectShipmentPartner = lazy(() =>
  import('./Orders/components/international/IntlSelectShipmentPartner')
);
const IntlConnectPartners = lazy(() =>
  import('./Manage/Delivery/IntlConnectPartners')
);
const TrackOrderDetailsMobile = lazy(() =>
  import('./Orders/TrackOrderDetailsMobile')
);
const SelfShipOrder = lazy(() => import('./Orders/components/SelfShipOrder'));
const SelectHyperlocalOrders = lazy(() =>
  import('./Orders/components/SelectHyperlocalOrders')
);
const CalculateShipmentWeight = lazy(() =>
  import('./Orders/components/CalculateShipmentWeight')
);
const IntlConfirmDeliveryCost = lazy(() =>
  import('./Orders/IntlConfirmDeliveryCost')
);

const Catalogue = lazy(() => import('./Catalogue'));
const Bundles = lazy(() => import('./Catalogue/Bundles'));
const NewBundle = lazy(() => import('./Catalogue/Bundles/NewBundle'));
const ProductAttributes = lazy(() =>
  import('./Catalogue/Products/ProductAttributes')
);
const NewProductWrapper = lazy(() =>
  import('./Catalogue/Products/NewProductWrapper')
);
const EditProductWrapper = lazy(() =>
  import('./Catalogue/Products/EditProductWrapper')
);

const NewBulkProduct = lazy(() =>
  import('./Catalogue/Products/NewBulkProduct')
);

const ProductInventoriesBulkUpdate = lazy(() =>
  import('./Catalogue/Products/ProductInventoriesBulkUpdate')
);
const NewCategory = lazy(() => import('./Catalogue/Categories/NewCategory'));
const NewSubcategory = lazy(() =>
  import('./Catalogue/Categories/NewSubcategory')
);
const EditSubcategory = lazy(() =>
  import('./Catalogue/Categories/EditSubcategory')
);
const EditCategory = lazy(() => import('./Catalogue/Categories/EditCategory'));

const Restaurant = lazy(() => import('./RestaurantDining'));
const AddRestaurantTable = lazy(() => import('./RestaurantDining/AddTable'));
const ViewTable = lazy(() => import('./RestaurantDining/ViewTable'));
const TableOrders = lazy(() => import('./RestaurantDining/TableOrders'));
const Manage = lazy(() => import('./Manage'));
const Marketing = lazy(() => import('./Marketing'));
const WhatsappDesigns = lazy(() => import('./Marketing/WhatsappDesigns'));
const TemplateList = lazy(() => import('./TemplateList'));
const Coupons = lazy(() => import('./Manage/Coupons'));
const NewCoupon = lazy(() => import('./Manage/Coupons/NewCoupon'));
const Analytics = lazy(() => import('./Analytics'));
const OrdersReport = lazy(() => import('./Analytics/OrdersReport'));
const SalesReport = lazy(() => import('./Analytics/SalesReport'));
const SalesByRegionReport = lazy(() =>
  import('./Analytics/SalesByRegionReport')
);
const SalesByTrafficReport = lazy(() =>
  import('./Analytics/SalesByTrafficReport')
);
const SalesByProductReport = lazy(() =>
  import('./Analytics/SalesByProductReport')
);

const SalesByCategoryReport = lazy(() =>
  import('./Analytics/SalesByCategoryReport')
);
const StoreOverview = lazy(() => import('./Analytics/StoreOverview'));
const StoreOverviewReport = lazy(() =>
  import('./Analytics/StoreOverviewReport')
);
const StoreSessionsReport = lazy(() =>
  import('./Analytics/StoreSessionsReport')
);
const SessionsByPageReport = lazy(() =>
  import('./Analytics/SessionsByPageReport')
);
const SessionsByUTMReport = lazy(() =>
  import('./Analytics/SessionsByUTMReport')
);
const ProductOverview = lazy(() => import('./Analytics/ProductOverview'));
const ProductViewsReport = lazy(() => import('./Analytics/ProductViewsReport'));

const Account = lazy(() => import('./Account'));
const StoreSettings = lazy(() => import('./Account/StoreSettings'));
const ShippingRules = lazy(() =>
  import('./Account/Sections/ConnectShippingPartners/ShippingRules')
);
const UpdateInfoSuccess = lazy(() => import('./Account/UpdateInfoSuccess'));
const UpdateAadharSuccess = lazy(() =>
  import('./Account/UpdateAadharSuccessModal')
);
const PluginsPage = lazy(() => import('./Plugins/PluginPage'));
const PluginsList = lazy(() => import('./Plugins/PluginList'));
const StoreLocator = lazy(() => import('./Plugins/StoreLocator'));
const NewOfflineStore = lazy(() =>
  import('./Plugins/StoreLocator/NewOfflineStore')
);
const EditOfflineStore = lazy(() =>
  import('./Plugins/StoreLocator/EditOfflineStore')
);

const AdditionalInformation = lazy(() =>
  import('./Account/AdditionalInformation')
);
const StoreQRCode = lazy(() => import('./Account/StoreQRCode'));
const DeleteAccount = lazy(() => import('./Account/DeleteAccount'));
const AccountDeletedSuccessfully = lazy(() =>
  import('./Account/AccountDeletedSuccessfully')
);
const TransactionFees = lazy(() => import('./Account/TransactionFees'));
const ReorderAttributes = lazy(() =>
  import('./Account/Sections/CategoryAttributes/ReorderAttributes')
);
const CreateAttribute = lazy(() =>
  import('./Account/Sections/CategoryAttributes/CreateAttribute')
);

const NotRegistered = lazy(() => import('./InternationalLogin/NotRegistered'));
const SingleCategory = lazy(() =>
  import('./Catalogue/Products/SingleCategory')
);
const Register = lazy(() => import('./Register/Register'));
const RegisterSuccess = lazy(() => import('./Register/RegisterSuccess'));
const SelectStore = lazy(() => import('./SelectStore'));

const EditCoupon = lazy(() => import('./Manage/Coupons/EditCoupon'));
const Customers = lazy(() => import('./Manage/Customers'));
const CustomerOrders = lazy(() => import('./Manage/Customers/CustomerOrders'));

const Payments = lazy(() => import('./Manage/Payments'));
const VerificationSuccess = lazy(() =>
  import('./Manage/Payments/VerificationSuccess')
);
const UpdatePaymentDetails = lazy(() =>
  import('./Manage/Payments/UpdatePaymentDetails')
);

const TestModals = lazy(() => import('./test'));
const OrderForm = lazy(() => import('./Manage/OrderForm'));
const OrderFormNewField = lazy(() => import('./Manage/OrderForm/NewField'));

const BusinessCards = lazy(() => import('./Marketing/BusinessCards'));
const StoreBanners = lazy(() => import('./Marketing/StoreBanners'));
const NewSubscription = lazy(() => import('./Subscription/NewSubscription'));
const SubscriptionSuccess = lazy(() =>
  import('./Subscription/NewSubscription/SubscriptionSuccess')
);
const MobileSubscription = lazy(() =>
  import('./Subscription/NewSubscription/MobileSuscriptionPage')
);
const MobileSubscriptionPurchaseSuccessScreen = lazy(() =>
  import('./Subscription/NewSubscription/MobilePurchaseSuccessScreen')
);
const MobileSubscriptionLogin = lazy(() =>
  import('./Subscription/NewSubscription/MobilePlanLogin')
);
const PlanDownGradeCauses = lazy(() =>
  import('./Subscription/NewSubscription/PlanDownGradeCauses')
);
const CancelSubscription = lazy(() =>
  import('./Subscription/CancelSubscription')
);
const ChooseCatalogCategory = lazy(() =>
  import('./Catalogue/Products/ChooseCatalogCategory')
);
const SelectCatalogProducts = lazy(() =>
  import('./Catalogue/Products/SelectCatalogProducts')
);
const PluginSettings = lazy(() => import('./Plugins/setting'));
const DukaanPlus = lazy(() => import('./Plugins/DukaanPlus'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const UpdatePhone = lazy(() => import('./Account/UpdatePhone'));
const InvoiceGenerator = lazy(() => import('./Manage/Invoices'));
const BackgroundRemover = lazy(() => import('./Manage/BackgroundRemover'));
const ImageEnhancer = lazy(() => import('./Manage/ImageEnhancer'));
const ProductDescription = lazy(() => import('./Manage/ProductDescription'));
const StoreTimings = lazy(() => import('./Manage/StoreTimings'));
const DukaanDelivery = lazy(() => import('./Manage/Delivery'));
const EditDukaanDelivery = lazy(() => import('./Manage/Delivery/warehouse'));
const WarehouseSettingsMobilePage = lazy(() =>
  import('./Manage/Delivery/WarehouseSettingsMobilePage')
);
const HsnCodeFinder = lazy(() => import('./Manage/HsnCodeFinder'));

const Pages = lazy(() => import('./Pages'));
const NewPage = lazy(() => import('./Pages/NewPage'));
const Blog = lazy(() => import('./Blog'));
const NewBlog = lazy(() => import('./Blog/NewBlog'));
const Media = lazy(() => import('./Media'));
const EditMedia = lazy(() => import('./Media/EditMedia'));

const Appearance = lazy(() => import('./Appearance'));

const CustomDomain = lazy(() => import('./Home/CustomDomain'));
const CustomDomainPending = lazy(() =>
  import('./Home/CustomDomain/CustomDomainPending')
);
const CustomDomainSuccess = lazy(() =>
  import('./Home/CustomDomain/CustomDomainSuccess')
);
const ConnectExistingDomainDetails = lazy(() =>
  import('./Home/CustomDomain/ConnectExistingDomainDetails')
);
const InternationalPayments = lazy(() =>
  import('./Manage/InternationalPayments')
);
const AuthorizeRazorpay = lazy(() =>
  import('./Manage/InternationalPayments/AuthorizeRazorpay')
);

const GetStoreApp = lazy(() => import('./Account/GetYourOwnApp'));

const CustomizeTheme = lazy(() => import('./Themes/CustomizeTheme'));
const Themes = lazy(() => import('./Themes'));
const Tutorials = lazy(() => import('./Tutorials'));
const Referral = lazy(() => import('./Referral'));
const SuccessfullReferrals = lazy(() =>
  import('./Referral/SuccessfulReferrals')
);

const Campaign = lazy(() => import('./Campaign'));
const CreateCampaign = lazy(() => import('./Campaign/NewCampaign'));
const CreateCampaignSuccess = lazy(() =>
  import('./Campaign/CreateCampaignSuccess')
);
const CampaignDetails = lazy(() => import('./Campaign/CampaignDetails'));

const CampaignCredits = lazy(() => import('./Campaign/Credits'));
const AddCreditsSuccess = lazy(() => import('./Campaign/AddCreditsSuccess'));
const AddCreditsFailure = lazy(() => import('./Campaign/AddCreditsFailure'));
const WhiteLabelWhatsAppSubscription = lazy(() =>
  import('./Plugins/WhatsappCommunication/SubscriptionPage')
);
const WishlistMessageBroadcastSuccess = lazy(() =>
  import('./Plugins/Wishlist/WishlistMessageBroadcastSuccess')
);

const CreateOrder = lazy(() => import('./Orders/CreateOrder'));
const CodeEditor = lazy(() => import('./CodeEditor'));
const EmailMarketingEditor = lazy(() =>
  import('./Campaign/EmailMarketing/EmailMarketingEditor')
);

const Inventory = lazy(() => import('./Catalogue/Inventory'));
const ViewAllReports = lazy(() => import('./ViewAllReports'));

export const TestModalRoute = [
  {
    path: testModalsUrl.testPath,
    exact: true,
    component: TestModals,
  },
];

export const unAuthenticatedRoutes = [
  {
    path: authUrls.loginPath,
    exact: true,
    component: InternationalLogin,
  },
  {
    path: authUrls.notRegisteredPath,
    exact: true,
    component: NotRegistered,
  },
  {
    path: accountUrls.accountDeleted,
    exact: true,
    component: AccountDeletedSuccessfully,
  },
  {
    path: authUrls.forgotPasswordPath,
    exact: true,
    component: ForgotPassword,
  },
  {
    path: accountUrls.subscriptionPurchaseMobilePlanLoginPath,
    exact: true,
    component: MobileSubscriptionLogin,
  },
  {
    path: accountUrls.staffAccountVerifySuccess,
    exact: true,
    component: StaffAccountSuccess,
  },
  {
    path: accountUrls.staffAccountVerifyError,
    exact: true,
    component: StaffAccountError,
  },
  {
    path: accountUrls.staffAccountAccessRevoked,
    exact: true,
    component: StaffAccountAccessRevoked,
  },
];

export const catalogueRoutes = [
  {
    path: catalogueUrls.bundlesPath,
    exact: true,
    component: Bundles,
  },
  {
    path: catalogueUrls.newBundlePath,
    exact: true,
    component: NewBundle,
  },
  {
    path: catalogueUrls.editBundlePath,
    exact: true,
    component: NewBundle,
  },
  {
    path: catalogueUrls.attributesPath,
    exact: true,
    component: ProductAttributes,
  },

  {
    path: catalogueUrls.attributesCreatePath,
    exact: true,
    component: CreateAttribute,
  },
  {
    path: catalogueUrls.reorderAttributesPath,
    exact: true,
    component: ReorderAttributes,
  },
  {
    path: catalogueUrls.editAttributes,
    exact: true,
    component: CreateAttribute,
  },
  {
    path: catalogueUrls.cataloguePath,
    exact: true,
    component: Catalogue,
  },
  {
    path: catalogueUrls.newProductPath,
    exact: true,
    component: NewProductWrapper,
  },
  {
    path: catalogueUrls.editProductPath,
    exact: true,
    component: EditProductWrapper,
  },
  {
    path: catalogueUrls.newCategoryPath,
    exact: true,
    component: NewCategory,
  },
  {
    path: catalogueUrls.newSubcategoryPath,
    exact: true,
    component: NewSubcategory,
  },
  {
    path: catalogueUrls.newProductBulkPath,
    exact: true,
    component: NewBulkProduct,
  },
  {
    path: catalogueUrls.productInventoriesBulkUpdatePath,
    exact: true,
    component: ProductInventoriesBulkUpdate,
  },
  {
    path: catalogueUrls.productBulkPricesPath,
    exact: true,
    component: Inventory,
  },
  {
    path: catalogueUrls.editSubcategoryPath,
    exact: true,
    component: EditSubcategory,
  },
  {
    path: catalogueUrls.editCategoryPath,
    exact: true,
    component: EditCategory,
  },
  {
    path: catalogueUrls.singleCategoryProductsPath,
    exact: true,
    component: SingleCategory,
  },
  {
    path: catalogueUrls.selectCatalogCategory,
    exact: true,
    component: ChooseCatalogCategory,
  },
  {
    path: catalogueUrls.selectCatalogProducts,
    exact: true,
    component: SelectCatalogProducts,
  },
];

export const pluginsRoutes = [
  {
    path: pluginsUrls.pluginsPath,
    exact: true,
    component: PluginsList,
  },
  {
    path: pluginsUrls.installedPluginsPath,
    exact: true,
    component: PluginsList,
  },
  {
    path: pluginsUrls.pluginDetailsPath,
    exact: true,
    component: PluginsPage,
  },
  {
    path: pluginsUrls.pluginSettingPath,
    exact: true,
    component: PluginSettings,
  },
  {
    path: pluginsUrls.dukaanPlusPath,
    exact: true,
    component: DukaanPlus,
  },
  {
    path: pluginsUrls.storeLocatorPath,
    exact: true,
    component: StoreLocator,
  },
  {
    path: `${pluginsUrls.storeLocatorPath}/new`,
    exact: true,
    component: NewOfflineStore,
  },
  {
    path: `${pluginsUrls.storeLocatorPath}/:uuid/edit`,
    exact: true,
    component: EditOfflineStore,
  },

  {
    path: pluginsUrls.whiteLabelWhatsapp,
    exact: true,
    component: WhiteLabelWhatsAppSubscription,
  },
];

export const campaignRoutes = [
  {
    path: campaignUrls.credits,
    exact: true,
    component: CampaignCredits,
  },
  {
    path: campaignUrls.addCreditsSuccess,
    exact: true,
    component: AddCreditsSuccess,
  },
  {
    path: campaignUrls.addCreditsFailure,
    exact: true,
    component: AddCreditsFailure,
  },
  {
    path: campaignUrls.wishlistSuccessMessagePath,
    exact: true,
    component: WishlistMessageBroadcastSuccess,
  },
  {
    path: campaignUrls.home,
    exact: true,
    component: Campaign,
  },

  {
    path: campaignUrls.createCampaign,
    exact: true,
    component: CreateCampaign,
  },
  {
    path: campaignUrls.campaignDetails,
    exact: true,
    component: CampaignDetails,
  },
  {
    path: campaignUrls.createCampaignSuccess,
    exact: true,
    component: CreateCampaignSuccess,
  },
  {
    path: campaignUrls.emailMarketingEditor,
    exact: true,
    component: EmailMarketingEditor,
  },
];

export const orderRoutes = [
  {
    path: rootUrls.ordersPath,
    exact: true,
    component: Orders,
  },
  {
    path: rootUrls.returnOrdersPath,
    exact: true,
    component: ReturnedOrders,
  },
  {
    path: rootUrls.abandonedOrdersPath,
    exact: true,
    component: AbandonedOrders,
  },
  {
    path: rootUrls.commissionReportPath,
    exact: true,
    component: CommissionReport,
  },
  {
    path: rootUrls.abandonedOrdersAdvanceSettings,
    exact: true,
    component: AbandonedOrdersAdvanceSettings,
  },
  {
    path: rootUrls.successRecoveryMessageBroadcastPath,
    exact: true,
    component: SuccessRecoveryMessageBroadcast,
  },
  {
    path: rootUrls.ordersMultipleSelectPartnersPath,
    exact: true,
    component: MultipleDeliveryPartners,
  },
  {
    path: rootUrls.ordersTrackingPath,
    exact: true,
    component: TrackOrderDetailsMobile,
  },
  {
    path: rootUrls.ordersMultipleShipPath,
    exact: true,
    component: ShipMultipleOrders,
  },
  {
    path: rootUrls.orderHyperlocalPath,
    exact: true,
    component: SelectHyperlocalOrders,
  },
  {
    path: rootUrls.calculateShipmentPricePath,
    exact: true,
    component: CalculateShipmentWeight,
  },
  {
    path: rootUrls.ordersMultipleShipConfirmPath,
    exact: true,
    component: ConfirmMultipleOrders,
  },
  {
    path: rootUrls.abandonedOrderDetailsPath,
    exact: true,
    component: OrderDetails,
  },
  {
    path: rootUrls.returnOrderDetailsPath,
    exact: true,
    component: OrderDetails,
  },
  {
    path: rootUrls.orderDetailsPath,
    exact: true,
    component: OrderDetails,
  },
  {
    path: rootUrls.orderEditPath,
    exact: true,
    component: EditOrder,
  },
  {
    path: rootUrls.orderDeliveryCostPath,
    exact: true,
    component: ConfirmDeliveryCost,
  },
  {
    path: rootUrls.orderReturnPickupCostPath,
    exact: true,
    component: ConfirmDeliveryCost,
  },
  {
    path: rootUrls.orderReplacementPickupCostPath,
    exact: true,
    component: ConfirmDeliveryCost,
  },
  {
    path: rootUrls.orderReplacementForwardCostPath,
    exact: true,
    component: ConfirmDeliveryCost,
  },
  {
    path: rootUrls.orderDeliveryShipmentWeightPath,
    exact: true,
    component: AddShipmentWeight,
  },
  {
    path: rootUrls.orderDeliveryPartnerPath,
    exact: true,
    component: SelectShipmentPartner,
  },
  {
    path: rootUrls.orderReturnPickupPartnerPath,
    exact: true,
    component: SelectReplacementShippingPartner,
  },
  {
    path: rootUrls.orderReplacementPickupPartnerPath,
    exact: true,
    component: SelectReplacementShippingPartner,
  },
  {
    path: rootUrls.orderReplacementForwardPartnerPath,
    exact: true,
    component: SelectReplacementShippingPartner,
  },
  {
    path: rootUrls.orderDeliveryIntlPartnerPath,
    exact: true,
    component: IntlSelectShipmentPartner,
  },
  {
    path: rootUrls.orderSelfShipPath,
    exact: true,
    component: SelfShipOrder,
  },
  {
    path: rootUrls.intlOrderDeliveryCostPath,
    exact: true,
    component: IntlConfirmDeliveryCost,
  },
  {
    path: rootUrls.createOrder,
    exact: true,
    component: CreateOrder,
  },
];

export const accountRoutes = [
  {
    path: accountUrls.accountPath,
    exact: true,
    component: Account,
  },
  {
    path: accountUrls.settingsPath,
    exact: true,
    component: StoreSettings,
  },
  {
    path: accountUrls.shippingRulesWarehousePath,
    exact: true,
    component: ShippingRules,
  },
  {
    path: accountUrls.shippingRulesConnectPartnersPath,
    exact: true,
    component: ShippingRules,
  },
  {
    path: accountUrls.shippingRulesPartnerPriorityPath,
    exact: true,
    component: ShippingRules,
  },
  {
    path: accountUrls.updateSuccessPath,
    exact: true,
    component: UpdateInfoSuccess,
  },
  {
    path: accountUrls.updateAadharSuccessPath,
    exact: true,
    component: UpdateAadharSuccess,
  },
  {
    path: accountUrls.additionalInformationPath,
    exact: true,
    component: AdditionalInformation,
  },
  {
    path: manageUrls.storeQRCodePath,
    exact: true,
    component: StoreQRCode,
  },
  {
    path: accountUrls.deleteAccount,
    exact: true,
    component: DeleteAccount,
  },
  {
    path: accountUrls.subscriptionPurchasePath,
    exact: true,
    component: NewSubscription,
  },
  {
    path: accountUrls.subscriptionSuccessPath,
    exact: true,
    component: SubscriptionSuccess,
  },
  {
    path: accountUrls.subscriptionPurchaseMobilePath,
    exact: true,
    component: MobileSubscription,
  },
  {
    path: accountUrls.subscriptionPurchaseMobileSuccess,
    exact: true,
    component: MobileSubscriptionPurchaseSuccessScreen,
  },
  {
    path: accountUrls.subscriptionCancelPath,
    exact: true,
    component: CancelSubscription,
  },
  {
    path: accountUrls.updatePhoneNumberUrl,
    exact: true,
    component: UpdatePhone,
  },
  {
    path: accountUrls.customDomain,
    exact: true,
    component: CustomDomain,
  },
  {
    path: accountUrls.customDomainSuccess,
    exact: true,
    component: CustomDomainSuccess,
  },
  {
    path: accountUrls.customDomainPending,
    exact: true,
    component: CustomDomainPending,
  },
  {
    path: accountUrls.customDomainExisting,
    exact: true,
    component: ConnectExistingDomainDetails,
  },
  {
    path: accountUrls.getStoreApp,
    exact: true,
    component: GetStoreApp,
  },
  {
    path: accountUrls.planDowngradePath,
    exact: true,
    component: PlanDownGradeCauses,
  },
  {
    path: accountUrls.createAttributes,
    exact: true,
    component: CreateAttribute,
  },
  {
    path: accountUrls.reorderAttributes,
    exact: true,
    component: ReorderAttributes,
  },
  {
    path: accountUrls.transactionFeeForOrders,
    exact: true,
    component: TransactionFees,
  },
];

export const customerRoutes = [
  {
    path: manageUrls.customersPath,
    exact: true,
    component: Customers,
  },
  {
    path: manageUrls.customerPath,
    exact: true,
    component: CustomerOrders,
  },
];

export const manageRoutes = [
  {
    path: rootUrls.managePath,
    exact: true,
    component: Manage,
  },
  {
    path: manageUrls.couponsPath,
    exact: true,
    component: Coupons,
  },
  {
    path: manageUrls.newCouponPath,
    exact: true,
    component: NewCoupon,
  },
  {
    path: manageUrls.editCouponPath,
    exact: true,
    component: EditCoupon,
  },
  {
    path: manageUrls.invoiceGeneratorPath,
    exact: true,
    component: InvoiceGenerator,
  },
  {
    path: manageUrls.backgroudRemoverPath,
    exact: true,
    component: BackgroundRemover,
  },
  {
    path: manageUrls.productDescriptionPath,
    exact: true,
    component: ProductDescription,
  },
  {
    path: manageUrls.storeTimingsPath,
    exact: true,
    component: StoreTimings,
  },
  {
    path: manageUrls.hsnCodeFinder,
    exact: true,
    component: HsnCodeFinder,
  },
  {
    path: manageUrls.imageEnhancerPath,
    exact: true,
    component: ImageEnhancer,
  },
  ...customerRoutes,
];

export const deliveryRoutes = [
  {
    path: manageUrls.dukaanDelivery,
    exact: true,
    component: DukaanDelivery,
  },
  {
    path: manageUrls.editDukaanDelivery,
    exact: true,
    component: EditDukaanDelivery,
  },
  {
    path: manageUrls.multiWarehouseSettingsPath,
    exact: true,
    component: WarehouseSettingsMobilePage,
  },
  {
    path: manageUrls.connectIntlPartners,
    exact: true,
    component: IntlConnectPartners,
  },
];

export const analyticsRoutes = [
  {
    path: analyticsUrl.businessOverviewPath,
    exact: true,
    component: Analytics,
  },
  {
    path: analyticsUrl.ordersReport,
    exact: true,
    component: OrdersReport,
  },
  {
    path: analyticsUrl.salesReport,
    exact: true,
    component: SalesReport,
  },
  {
    path: analyticsUrl.salesByRegionReport,
    exact: true,
    component: SalesByRegionReport,
  },
  {
    path: analyticsUrl.salesByTrafficSource,
    exact: true,
    component: SalesByTrafficReport,
  },
  {
    path: analyticsUrl.storeOverviewPath,
    exact: true,
    component: StoreOverview,
  },
  {
    path: analyticsUrl.storeOverviewReport,
    exact: true,
    component: StoreOverviewReport,
  },
  {
    path: analyticsUrl.storeSessionsReport,
    exact: true,
    component: StoreSessionsReport,
  },
  {
    path: analyticsUrl.storeSessionsByPageReport,
    exact: true,
    component: SessionsByPageReport,
  },
  {
    path: analyticsUrl.storeSessionsByUTMReport,
    exact: true,
    component: SessionsByUTMReport,
  },
  {
    path: analyticsUrl.productOverviewPath,
    exact: true,
    component: ProductOverview,
  },
  {
    path: analyticsUrl.productViewsReport,
    exact: true,
    component: ProductViewsReport,
  },
  {
    path: analyticsUrl.salesByProductReport,
    exact: true,
    component: SalesByProductReport,
  },
  {
    path: analyticsUrl.salesByCategoryReport,
    exact: true,
    component: SalesByCategoryReport,
  },
];

export const paymentRoutes = [
  {
    path: manageUrls.paymentsUrl,
    exact: true,
    component: Payments,
  },
  {
    path: manageUrls.paymentVerificationSuccessUrl,
    exact: true,
    component: VerificationSuccess,
  },
  {
    path: manageUrls.updatePaymentDetail,
    exact: true,
    component: UpdatePaymentDetails,
  },
  {
    path: manageUrls.setupPaymentsUrl,
    exact: true,
    component: InternationalPayments,
  },
  {
    path: internationPaymentsUrl.razorpayAuthUrl,
    exact: true,
    component: AuthorizeRazorpay,
  },
];

export const orderFormRoutes = [
  {
    path: manageUrls.orderForm,
    exact: true,
    component: OrderForm,
  },
  {
    path: manageUrls.orderFormNewField,
    exact: true,
    component: OrderFormNewField,
  },
  {
    path: manageUrls.orderFormEditField,
    exact: true,
    component: OrderFormNewField,
  },
];

export const marketingRoutes = [
  {
    path: marketingUrls.marketingPath,
    exact: true,
    component: Marketing,
  },
  {
    path: marketingUrls.viewDesigns,
    exact: true,
    component: TemplateList,
  },
  {
    path: marketingUrls.whatsAppDesigns,
    exact: true,
    component: WhatsappDesigns,
  },
  {
    path: marketingUrls.businessCards,
    exact: true,
    component: BusinessCards,
  },
  {
    path: marketingUrls.storeBanners,
    exact: true,
    component: StoreBanners,
  },
];

export const restaurantRoutes = [
  {
    path: restaurantUrls.viewRestaurant,
    exact: true,
    component: Restaurant,
  },
  {
    path: restaurantUrls.viewTable,
    exact: true,
    component: ViewTable,
  },
  {
    path: restaurantUrls.tableOrders,
    exact: true,
    component: TableOrders,
  },
  {
    path: restaurantUrls.addTable,
    exact: true,
    component: AddRestaurantTable,
  },
];

export const pagesRoutes = [
  {
    path: pagesUrls.pages,
    exact: true,
    component: Pages,
  },
  {
    path: pagesUrls.addPage,
    exact: true,
    component: NewPage,
  },
  {
    path: pagesUrls.viewPage,
    exact: true,
    // eslint-disable-next-line react/display-name
    render: (props) => <NewPage {...props} isPublished />,
  },
];

export const blogRoutes = [
  {
    path: blogUrls.blog,
    exact: true,
    component: Blog,
  },
  {
    path: blogUrls.addBlog,
    exact: true,
    component: NewBlog,
  },
  {
    path: blogUrls.viewBlog,
    exact: true,
    // eslint-disable-next-line react/display-name
    render: (props) => <NewBlog {...props} isPublished />,
  },
];

export const mediaRoutes = [
  {
    path: mediaLibraryUrls.media,
    exact: true,
    component: Media,
  },
  {
    path: mediaLibraryUrls.editMedia,
    exact: true,
    component: EditMedia,
  },
];

export const appearanceRoutes = [
  {
    path: appearanceUrls.menu,
    exact: true,
    component: Appearance,
  },
  {
    path: appearanceUrls.themes,
    exact: true,
    component: Themes,
  },
  {
    path: appearanceUrls.customizeTheme,
    exact: true,
    component: CustomizeTheme,
  },
  {
    path: appearanceUrls.codeEditor,
    exact: true,
    component: CodeEditor,
  },
];

export const authenticatedRoutes = [
  {
    path: authUrls.registerPath,
    exact: true,
    component: Register,
  },
  {
    path: authUrls.registerSuccessPath,
    exact: true,
    component: RegisterSuccess,
  },
  {
    path: authUrls.selectStorePath,
    exact: true,
    component: SelectStore,
  },
  ...TestModalRoute,
];

export const tutorialRoutes = [
  {
    path: tutorialUrls.tutorials,
    exact: true,
    component: Tutorials,
  },
];

export const referralRoutes = [
  {
    path: referralUrls.home,
    exact: true,
    component: Referral,
  },
  {
    path: referralUrls.successfullReferrals,
    exact: true,
    component: SuccessfullReferrals,
  },
];

export const dashboardRoutes = [
  {
    path: rootUrls.homePath,
    exact: true,
    component: Home,
  }, // all except vendor
  {
    path: accountUrls.resellerSwitch,
    exact: true,
    component: Reseller,
  },
  {
    path: rootUrls.viewAllReportsPath,
    exact: true,
    component: ViewAllReports,
  }, // all
  ...paymentRoutes, // admin
  ...orderRoutes, // staff
  ...deliveryRoutes, // all
  ...manageRoutes, // manager
  ...catalogueRoutes, // vendor
  ...pluginsRoutes, // manager
  ...marketingRoutes, // manager
  ...accountRoutes, // admin
  ...pagesRoutes, // manager
  ...blogRoutes, // manager
  ...mediaRoutes, // manager
  ...appearanceRoutes, // manager
  ...orderFormRoutes, // all
  ...restaurantRoutes, // manager
  ...tutorialRoutes, // manager
  ...campaignRoutes, // manager
  ...analyticsRoutes,
  // ...referralRoutes, // referral routes hidden
];

const dashboard = [
  {
    path: rootUrls.homePath,
    exact: true,
    component: Home,
  },
];

const forAll = [
  {
    path: accountUrls.resellerSwitch,
    exact: true,
    component: Reseller,
  },
  {
    path: rootUrls.viewAllReportsPath,
    exact: true,
    component: ViewAllReports,
  },
  {
    path: accountUrls.subscriptionPurchasePath,
    exact: true,
    component: NewSubscription,
  },
  ...deliveryRoutes,
];

const forVendor = [...catalogueRoutes, ...pluginsRoutes];

const forStaff = [...orderRoutes, ...orderFormRoutes, ...catalogueRoutes];
const forStaffL1 = [...orderRoutes];
const forStaffL2 = [...orderRoutes, ...catalogueRoutes];
const forStaffL3 = [...orderRoutes, ...catalogueRoutes, ...customerRoutes];

const forManager = [
  ...campaignRoutes,
  ...manageRoutes,
  ...pluginsRoutes,
  ...marketingRoutes,
  ...pagesRoutes,
  ...blogRoutes,
  ...mediaRoutes,
  ...appearanceRoutes,
  ...restaurantRoutes,
  ...tutorialRoutes,
  ...referralRoutes,
  ...analyticsRoutes,
  ...catalogueRoutes,
];
const forManagerL1 = [...orderRoutes, ...catalogueRoutes, ...customerRoutes];
const forManagerL2 = [...orderRoutes, ...paymentRoutes];

const forAdmin = [...paymentRoutes, ...accountRoutes];

export const getDashboardRoutes = (role) => {
  if ([STAFF_ROLES.OWNER, STAFF_ROLES.ADMIN].includes(role)) {
    return [
      ...forAll,
      ...forManager,
      ...forAdmin,
      ...forStaff,
      ...forVendor,
      ...dashboard,
    ];
  }

  if (STAFF_ROLES.MANAGER === role) {
    return [...forAll, ...forManager, ...forStaff, ...forVendor, ...dashboard];
  }

  if (STAFF_ROLES.MANAGER_L1 === role) {
    return [...forAll, ...forManagerL1];
  }

  if (STAFF_ROLES.MANAGER_L2 === role) {
    return [...forAll, ...forManagerL2];
  }

  if (STAFF_ROLES.STAFF === role) {
    return [...forAll, ...forStaff];
  }

  if (STAFF_ROLES.STAFF_L1 === role) {
    return [...forAll, ...forStaffL1];
  }

  if (STAFF_ROLES.STAFF_L2 === role) {
    return [...forAll, ...forStaffL2];
  }

  if (STAFF_ROLES.STAFF_L3 === role) {
    return [...forAll, ...forStaffL3];
  }

  if (role === STAFF_ROLES.VENDOR) {
    return [...forAll, ...forVendor];
  }

  return [...forAll];
};
